import React, { useState } from "react"

import { Card, CardBody, Col, Row, TabPane } from "reactstrap"
import { Link } from "react-router-dom"
import PaymentMethodLogos from "./PaymentMethodLogos"
import { finderPrices } from "constants/SetupOptions"
import { useDispatch } from "react-redux"
import { changeLoading } from "store/actions"
import {
  createSubscription,
  createSubscriptionMonthly,
} from "helpers/stripe_backend_helper"
import InvoicePromoCodeInfo from "./InvoicePromoCodeInfo"

const PaymentPane = ({
  finder,
  userEmail,
  toggleTabVertical,
  activeTabVartical,
  setoggleTabVertical,
  setInvoiceCreated,
  finalizeFinder,
}) => {
  const base_URL = process.env.REACT_APP_BACKEND_URL + "/stripe"
  const monthlyPrice = process.env.REACT_APP_FINDER_PRICE_MONTHLY
  const yearlyPrice = process.env.REACT_APP_FINDER_PRICE_YEARLY
  const [modal, setModal] = useState()
  const isMobile = screen.width < 1000
  const dispatch = useDispatch()

  async function handleInvoiceCreation(priceID) {
    dispatch(changeLoading(true))
    const paymentData = await createSubscription(priceID, finder)
    setInvoiceCreated(true)
    await finalizeFinder(paymentData.customerID)
    setoggleTabVertical(4)
    dispatch(changeLoading(false))
  }

  async function handleInvoiceCreationMonthly(priceID) {
    dispatch(changeLoading(true))
    const paymentData = await createSubscriptionMonthly(priceID, finder)
    setInvoiceCreated(true)
    await finalizeFinder(paymentData.customerID)
    setoggleTabVertical(4)
    setModal(false)
    dispatch(changeLoading(false))
  }

  return (
    <TabPane tabId={3}>
      <div className="row justify-content-center">
        <div className="text-center">
          <div>
            <h4>Ihre Zukunft, Ihre Entscheidung!</h4>
            <p className="text-muted">
              Damit du Sie Ihre E-Mail nicht erneut beim Zahlungsanbierter
              (Stripe, Inc.) eingeben müssen,
              <br /> leiten wir Ihre E-Mail automatisch weiter. meet2find. Ganz
              einfach.
            </p>
          </div>
        </div>
        {isMobile ? (
          <>
            <Card className="plan-box border border-2">
              <CardBody className="p-2 py-4">
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <h5 className="text-center">Monatlich</h5>
                    <p className="text-muted text-center py-2">
                      Ihr Account ist monatlich kündbar.
                    </p>
                  </div>
                </div>
                <div className="py-3">
                  <h2 className="text-center" style={{ fontSize: "30px" }}>
                    99 €
                  </h2>{" "}
                  <span className="text-center text-muted d-block mb-2">
                    exkl. 20% Mwst.
                  </span>
                  <h4 className="font-size-18 text-muted text-center">
                    pro Monat
                  </h4>
                </div>
                <div className="text-center">
                  <button
                    className="btn btn-outline-primary mb-2 w-100"
                    onClick={_ => setModal(true)}
                  >
                    Auf Rechnung
                  </button>
                  <form
                    action={
                      base_URL + "/create-finder-checkout/" + monthlyPrice
                    }
                    method="POST"
                  >
                    <input
                      type={"hidden"}
                      name="email"
                      id="email"
                      value={userEmail}
                    />
                    <button className="btn btn-primary w-100 px-1">
                      jetzt zahlungspflichtig abonnieren <br /> & bezahlen
                    </button>
                  </form>
                </div>
              </CardBody>
            </Card>
            <Card className="plan-box border border-2">
              <CardBody className="p-2 py-4">
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <h5 className="text-center">Jährlich</h5>
                    <p className="text-muted text-center py-2">
                      Ihr Account ist jährlich kündbar.
                    </p>
                  </div>
                </div>
                <div className="py-3">
                  <h2 className="text-center" style={{ fontSize: "30px" }}>
                    <small className="me-2">
                      <s className="text-muted">1188 €</s>
                    </small>
                    950 € 
                  </h2>
                  <span className="text-center text-muted d-block mb-2">
                    exkl. 20% Mwst.
                  </span>
                  <h4 className="font-size-18 text-muted text-center">
                    pro Jahr
                  </h4>
                </div>
                <div className="text-center">
                  <button
                    className="btn btn-outline-primary mb-2 w-100"
                    onClick={_ => handleInvoiceCreation(yearlyPrice)}
                  >
                    Auf Rechnung
                  </button>
                  <form
                    action={base_URL + "/create-finder-checkout/" + yearlyPrice}
                    method="POST"
                  >
                    <input
                      type={"hidden"}
                      name="email"
                      id="email"
                      value={userEmail}
                    />
                    <button className="btn btn-primary w-100 px-1">
                      jetzt zahlungspflichtig abonnieren <br /> & bezahlen
                    </button>
                  </form>
                </div>
              </CardBody>
            </Card>
          </>
        ) : (
          <Row className="justify-content-md-center">
            <Col xl="4">
              <Card className="plan-box border border-2">
                <CardBody className="p-4">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h5 className="text-center">Monatlich</h5>
                      <p className="text-muted text-center py-2">
                        Ihr Account ist monatlich kündbar.
                      </p>
                    </div>
                  </div>
                  <div className="py-3">
                    <h2 className="text-center" style={{ fontSize: "30px" }}>
                      99 €
                    </h2>{" "}
                    <span className="text-center text-muted d-block mb-2">
                      exkl. 20% Mwst.
                    </span>
                    <h4 className="font-size-18 text-muted text-center">
                      pro Monat
                    </h4>
                  </div>
                  <div className="text-center">
                    <button
                      className="btn btn-outline-primary mb-2 w-100"
                      onClick={_ => setModal(true)}
                    >
                      Auf Rechnung
                    </button>
                    <form
                      action={
                        base_URL + "/create-finder-checkout/" + monthlyPrice
                      }
                      method="POST"
                    >
                      <input
                        type={"hidden"}
                        name="email"
                        id="email"
                        value={userEmail}
                      />
                      <button className="btn btn-primary w-100">
                        jetzt zahlungspflichtig abonnieren <br /> & bezahlen
                      </button>
                    </form>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="4">
              <Card className="plan-box border border-2">
                <CardBody className="p-4">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h5 className="text-center">Jährlich</h5>
                      <p className="text-muted text-center py-2">
                        Ihr Account ist jährlich kündbar.
                      </p>
                    </div>
                  </div>
                  <div className="py-3">
                    <h2 className="text-center" style={{ fontSize: "30px" }}>
                      <small className="me-2">
                        <s className="text-muted">1188 €</s>
                      </small>
                      950 € 
                    </h2>
                    <span className="text-center text-muted d-block mb-2">
                      exkl. 20% Mwst.
                    </span>
                    <h4 className="font-size-18 text-muted text-center">
                      pro Jahr
                    </h4>
                  </div>
                  <div className="text-center">
                    <button
                      className="btn btn-outline-primary mb-2 w-100"
                      onClick={_ => handleInvoiceCreation(yearlyPrice)}
                    >
                      Auf Rechnung
                    </button>
                    <form
                      action={
                        base_URL + "/create-finder-checkout/" + yearlyPrice
                      }
                      method="POST"
                    >
                      <input
                        type={"hidden"}
                        name="email"
                        id="email"
                        value={userEmail}
                      />
                      <button className="btn btn-primary w-100">
                        jetzt zahlungspflichtig abonnieren <br /> & bezahlen
                      </button>
                    </form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        <Row>
          <span className="text-muted w-100 text-center">
            <small>
              Alle Preise verstehen sich als Nettopreise und beinhalten{" "}
              <u>keine</u> Umsatzsteuer.
            </small>
          </span>
          <span className="text-muted w-100 text-center">
            <small>
              Lieferung bzw. Freischaltung erfolgt umgehend nach Bezahlauftrag.
            </small>
          </span>
        </Row>
        <Row className="text-center my-4">
          <span className="d-block mb-1">Wir akzeptieren</span>
          <PaymentMethodLogos />
        </Row>
        <Row className="text-center items-center">
          <Link
            to="#"
            onClick={() => {
              toggleTabVertical(activeTabVartical - 1)
            }}
            className="d-flex align-items-center justify-content-center"
          >
            <i className=" bx bx-left-arrow-alt" />
            zurück
          </Link>
        </Row>
      </div>
      <InvoicePromoCodeInfo
        setModal={setModal}
        modal={modal}
        callback={_ => handleInvoiceCreationMonthly(monthlyPrice)}
      />
    </TabPane>
  )
}

export default PaymentPane
