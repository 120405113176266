import axios from "axios"

const API_URL = process.env.REACT_APP_BACKEND_URL

const axiosApi = axios.create({
  baseURL: API_URL,
})

const setHeader = () => {
  const token = localStorage.getItem("token")
  axiosApi.defaults.headers.common["token"] = token
}

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  setHeader()
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  setHeader()
  return axiosApi.post(url, { ...data }, { ...config }).then(response => {
    return response.data
  })
}

export async function upload(url, data) {
  setHeader()
  return axiosApi
    .post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  setHeader()
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  setHeader()
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}

export function handleError(error) {
  if (error?.response?.status === 403)
    window.location.href = "/email-verification"
  else if (error?.response?.status === 401) window.location.href = "/logout"
  else window.location.href = "/500"
}
