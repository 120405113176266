import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import { Link } from "react-router-dom"
import {
  touchedFinderValues,
  initialFinderValues,
} from "../../constants/SetupOptions"
import { useDispatch } from "react-redux"
import { changeLoading } from "store/actions"
import { getCurrentFinder, updateFinder } from "helpers/finder_backend_helper"
import GeneralInfosPane from "pages/Setup-Finder/Panes/GeneralInfosPane"
import WhyUsPane from "pages/Setup-Finder/Panes/WhyUsPane"

const FinderEdit = props => {
  const [finder, setFinder] = useState(initialFinderValues)
  const [touched, setTouched] = useState(touchedFinderValues)
  const [vatError, setVatError] = useState(false)
  const [error, setError] = useState(false)
  const [companyNrError, setCompanyNrError] = useState(false)
  const [activeTabVartical, setoggleTabVertical] = useState(1)
  const dispatch = useDispatch()
  const navItems = ["Generelle Infos", "Was zeichnet uns aus?"]

  useEffect(() => {
    async function setup() {
      dispatch(changeLoading(true))
      const user = await getCurrentFinder()
      setFinder(user)
      dispatch(changeLoading(false))
    }
    setup()
  }, [])

  function toggleTab() {
    if (allFieldsAreFilledOut())
      if (activeTabVartical === 2) handleSubmit()
      else toggleTabVertical(activeTabVartical + 1)
    else {
      setError(true)
      setTimeout(() => {
        setError(false)
      }, 3000)
    }
  }

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      if (tab >= 1 && tab <= 4) setoggleTabVertical(tab)
      setTimeout(() => {
        scrollTo(0, 0)
      }, 50)
    }
  }

  function allFieldsAreFilledOut() {
    if (activeTabVartical === 1) {
      setTouched({
        ...touched,
        companyName: true,
        contactPerson: true,
        vatNumber: true,
        companyNr: true,
        field: true,
        street: true,
        city: true,
        postalCode: true,
      })

      if (!finder.companyName) return false
      if (!finder.contactPerson) return false
      if (vatError) return false
      if (companyNrError) return false
      if (!finder.field) return false
      if (!finder.companySize) return false
      if (!finder.street) return false
      if (!finder.city) return false
      if (!finder.postalCode) return false
      if (!finder.gender) return false
    } else {
      if (!finder.whyUs) return false
    }

    return true
  }

  async function handleSubmit() {
    dispatch(changeLoading(true))

    const maxJobs = mapSizeToJobsCount(finder.companySize)
    const newFinder = {
      ...finder,
      maxJobs,
    }

    await updateFinder(newFinder)
    setFinder(newFinder)

    window.location.href = "/finder-dashboard"
  }

  function mapSizeToJobsCount(companySize) {
    switch (companySize) {
      case "0":
        return 1
      case "1":
        return 2
      case "2":
        return 3
      case "3":
        return 4
      default:
        return 1
    }
  }

  return (
    <React.Fragment>
      <div
        className="page-content bg-primary bg-soft"
        style={{ minHeight: "850px" }}
      >
        <Container fluid={true}>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Profil erstellen</h4>
                  <div className="vertical-wizard wizard clearfix vertical">
                    <div className="steps clearfix">
                      <ul>
                        {navItems.map((item, idx) => (
                          <NavItem
                            key={idx + 1}
                            className={classnames({
                              current: activeTabVartical === idx + 1,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTabVartical === idx + 1,
                              })}
                              disabled
                            >
                              <span className="number">{idx + 1}.</span> {item}
                            </NavLink>
                          </NavItem>
                        ))}
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent
                        activeTab={activeTabVartical}
                        className="body"
                      >
                        <GeneralInfosPane
                          finder={finder}
                          setFinder={setFinder}
                          vatError={vatError}
                          setVatError={setVatError}
                          companyNrError={companyNrError}
                          setCompanyNrError={setCompanyNrError}
                          touched={touched}
                          setTouched={setTouched}
                        />

                        <WhyUsPane
                          finder={finder}
                          setFinder={setFinder}
                          touched={touched}
                          setTouched={setTouched}
                        />
                      </TabContent>
                    </div>

                    <div className="actions clearfix">
                      <ul>
                        {error && (
                          <li>
                            <p className="text-danger">
                              Es müssen alle Pflichtfelder befüllt sein
                            </p>
                          </li>
                        )}
                        <li
                          className={
                            activeTabVartical === 1
                              ? "previous disabled"
                              : "previous"
                          }
                        >
                          <Link
                            to="#"
                            className="btn"
                            onClick={() => {
                              toggleTabVertical(activeTabVartical - 1)
                            }}
                          >
                            Zurück
                          </Link>
                        </li>
                        <li>
                          <button
                            className="next btn btn-primary"
                            onClick={toggleTab}
                          >
                            {activeTabVartical === 2 ? "Speichern" : "Weiter"}
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FinderEdit
