import { get, handleError, post } from "./api_helper"

export async function login({ email, password }) {
  try {
    const result = await post(`/auth/login`, {
      email,
      password,
    })

    localStorage.setItem("token", result.token)
    localStorage.setItem("email", email)
    localStorage.setItem("userType", result.userType)

    return result
  } catch (error) {
    console.log(error)
    throw error.response.data.error
  }
}

export async function signup({ email, password, userType }) {
  try {
    return await post(`/auth/signup`, {
      email,
      password,
      userType,
    })
  } catch (error) {
    throw error.response.data.error
  }
}

export async function sendVerificationEmail() {
  try {
    return await post(`/auth/sendVerificationEmail`, {})
  } catch (error) {
    handleError(error)
  }
}

export async function verifyEmail(email) {
  try {
    return await post(`/auth/verifyEmail`, {
      email,
    })
  } catch (error) {
    throw error.response.data.error
  }
}

export async function isEmailVerified(email) {
  try {
    return await get(`/auth/isEmailVerified/${email}`)
  } catch (error) {
    throw error.response.data.error
  }
}

export async function forgotPassword(email) {
  try {
    return await post(`/auth/forgotPassword`, {
      email,
    })
  } catch (error) {
    throw error.response.data.error
  }
}

export async function resetPassword(newPassword, actionCode) {
  try {
    return await post(`/auth/resetPassword`, {
      newPassword,
      actionCode,
    })
  } catch (error) {
    throw error
  }
}

export async function changePassword(newPassword) {
  try {
    return await post(`/auth/changePassword`, {
      newPassword,
    })
  } catch (error) {
    throw error
  }
}

export async function updateEmail(newEmail, customerID) {
  try {
    return await post(`/auth/updateEmail`, {
      newEmail,
      customerID,
    })
  } catch (error) {
    if (error?.response?.status === 400) throw error?.response?.data?.error
    handleError(error)
  }
}
