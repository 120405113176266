import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import JobFilter from "./JobFilter"
import JobData from "./JobData"
import { useDispatch } from "react-redux"
import { changeLoading } from "store/actions"
import { getMeeterJobOffers } from "helpers/meeter_backend_helper"

const MeeterJobOffers = () => {
  const [filterOption, setFilterOption] = useState("open")
  const [jobs, setJobs] = useState()
  const dispatch = useDispatch()

  useEffect(() => {
    async function getJobOffers() {
      dispatch(changeLoading(true))
      const result = await getMeeterJobOffers(filterOption)
      setJobs(result)
      dispatch(changeLoading(false))
    }
    getJobOffers()
  }, [filterOption])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Jobs" breadcrumbItem="Meine Jobangebote" />
          <JobFilter
            setFilterOption={setFilterOption}
            filterOption={filterOption}
          />
          <JobData jobs={jobs} filterOption={filterOption} />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MeeterJobOffers
