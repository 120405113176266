import { sendContactConfirmatiion } from "helpers/mail_helper"
import { updateJobOfferStatus } from "helpers/meeter_backend_helper"
import moment from "moment"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import { changeLoading } from "store/actions"

const Overview = ({ job, history }) => {
  const userEmail = localStorage.getItem("email")
  const [modal, setModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [success, setSuccess] = useState(false)
  const [type, setType] = useState()
  const dispatch = useDispatch()

  const mapCompanySize = idx => {
    const sizes = process.env.REACT_APP_COMPANY_SIZES?.split(",")
    return sizes[idx - 1]
  }

  const handleSubmit = async status => {
    dispatch(changeLoading(true))

    await updateJobOfferStatus(job.id, status)

    if (status === "accepted")
      sendContactConfirmatiion(job.finder.email, job.id)

    setModal(false)
    setDeleteModal(false)
    setType(status)
    setSuccess(true)
    dispatch(changeLoading(false))
  }

  return (
    <React.Fragment>
      <Col xl={3}>
        <Card>
          <CardBody>
            <h5 className="fw-semibold">Übersicht</h5>

            <div className="table-responsive">
              <table className="table">
                <tbody>
                  <tr>
                    <th scope="col">Firma</th>
                    <td scope="col">{job?.finder?.companyName}</td>
                  </tr>
                  <tr>
                    <th scope="row">Größe</th>
                    <td>
                      {mapCompanySize(job?.finder?.companySize)} Mitarbeiter
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">Branche</th>
                    <td scope="col">{job?.finder?.field}</td>
                  </tr>
                  <tr>
                    <th scope="col">Firmensitz</th>
                    <td scope="col">
                      {job?.finder?.postalCode + " " + job?.finder?.city}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Jobtyp</th>
                    <td>{job?.workingHours?.join(", ")}</td>
                  </tr>
                  <tr>
                    <th scope="row">Homeoffice:</th>
                    <td>{job?.homeOffice}</td>
                  </tr>
                  <tr>
                    <th scope="row">Bruttogehalt:</th>
                    <td>
                      {parseInt(job?.sallery)?.toLocaleString("de-DE")} € / Jahr
                    </td>
                  </tr>
                  {job?.salleryInfo && (
                    <tr>
                      <th scope="row">Zusatzinfo:</th>
                      <td>{job?.salleryInfo}</td>
                    </tr>
                  )}
                  <tr>
                    <th scope="row">Startdatum</th>
                    <td>{moment(job?.startDate).format("DD.MM.YYYY")}</td>
                  </tr>
                  {job?.finder?.contactPerson && (
                    <tr>
                      <th scope="row">Kontaktperson:</th>
                      <td>
                        {job?.finder?.gender + " " + job?.finder?.contactPerson}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {job?.meeters?.find(m => m.email === userEmail)?.status ===
              "open" && (
              <div className="gap-2">
                <button
                  className="btn btn-soft-success w-100"
                  onClick={() => setModal(true)}
                >
                  Kontaktdaten freigeben
                </button>
                <button
                  className="btn btn-soft-danger w-100 mt-2"
                  onClick={() => setDeleteModal(true)}
                >
                  Ablehnen
                </button>
              </div>
            )}
          </CardBody>
        </Card>
      </Col>
      {/* Modal */}
      <Modal
        isOpen={success}
        centered
        toggle={() => {
          setSuccess()
        }}
      >
        <div className="modal-content">
          <ModalBody>
            <Row>
              <h4>{type === "accepted" ? "Gratulation!" : "Schade!"}</h4>
              <p>
                {type === "accepted"
                  ? "Wir haben soeben deine Kontaktdaten an den Finder weitergeleitet. Viel Erfolg beim Kennenlernen!"
                  : "Wir haben soeben das Jobangebot vom Finder abgelehnt. Es werden sicher noch einige Anfragen kommen.."}
              </p>
              <Col lg={12}>
                <div className="text-end">
                  <button
                    className="btn btn-success me-1"
                    onClick={() => history.push("/jobangebote")}
                  >
                    Zurück zum Dashboard
                  </button>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>
      <Modal
        isOpen={modal}
        toggle={() => {
          setModal()
        }}
        centered
        id="applyJobs"
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => setModal()}
            id="applyJobsLabel"
            className="modal-header"
          >
            Kontaktdaten freigeben
          </ModalHeader>
          <ModalBody>
            <Row>
              <h4>Freigabe!</h4>
              <p>
                Es freut uns, dass du einen passenden Job gefunden hast. Wir
                würden jetzt deine Kontaktdaten freigeben. Ist das für dich
                okay?
              </p>

              <Col lg={12}>
                <div className="text-end">
                  <button
                    className="btn btn-outline-secondary"
                    onClick={_ => setModal(false)}
                  >
                    Abbrechen
                  </button>
                  <button
                    className="btn btn-success ms-2"
                    onClick={_ => handleSubmit("accepted")}
                  >
                    Ja, Kontaktdaten freigeben{" "}
                  </button>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>
      <Modal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal()
        }}
        centered
        id="applyJobs"
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => setDeleteModal()}
            id="applyJobsLabel"
            className="modal-header"
          >
            Finder ablehnen
          </ModalHeader>
          <ModalBody>
            <Row>
              <h4>Angebot ablehnen!</h4>
              <p>
                Bist du dir sicher, dass du das Angebot ablehnen möchtest?
                Dieser Schritt kann nicht rückgängig gemacht werden!
              </p>
              <Col lg={12}>
                <div className="text-end">
                  <button
                    className="btn btn-outline-secondary"
                    onClick={_ => setDeleteModal(false)}
                  >
                    Abbrechen
                  </button>
                  <button
                    className="btn btn-danger ms-2"
                    onClick={() => handleSubmit("declined")}
                  >
                    Ja, Angebot ablehnen
                  </button>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default Overview
