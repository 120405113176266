import React, { useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { Link } from "react-router-dom"

//import images
import { useDispatch } from "react-redux"
import { changeLoading } from "store/actions"
import { updateFinderJob } from "helpers/finder_backend_helper"

const JobData = ({ finder, filter }) => {
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedJob, setSelectedJob] = useState()
  const joblist = finder?.jobs?.filter(
    j => !filter || j.jobTitle?.toLowerCase()?.includes(filter.toLowerCase())
  )
  const dispatch = useDispatch()

  const handleDelete = async _ => {
    dispatch(changeLoading(true))
    const job = finder.jobs.find(j => j.id === selectedJob)
    job.isActive = false
    await updateFinderJob(job)
    setDeleteModal(false)
    dispatch(changeLoading(false))
  }

  const handleClickDelelte = jobID => {
    setSelectedJob(jobID)
    setDeleteModal(true)
  }

  return (
    <React.Fragment>
      <Row>
        {(!finder?.jobs || finder.jobs?.length == 0) && (
          <p className="text-center mt-4">
            Jetzt Jobangebot erstellen und Mitarbeiter:innen finden
          </p>
        )}
        {(joblist || []).map((item, key) => (
          <Col xl={3} md={6} key={key}>
            <Card>
              <CardBody className="position-relativ">
                {item.isActive && (
                  <Link
                    to="#"
                    className="position-absolute end-0 me-4 cursor-pointer"
                    onClick={_ => handleClickDelelte(item.id)}
                  >
                    <i className="bx bx-trash font-size-20 text-danger"></i>
                  </Link>
                )}
                <h5 className="fs-17 mb-2">
                  <Link
                    to={item.isActive ? "/finder-jobs/" + item.id : "#"}
                    className="text-dark"
                  >
                    {item.jobTitle}
                  </Link>{" "}
                </h5>
                <ul className="list-inline mb-0">
                  <li className="list-inline-item">
                    <p className="text-muted fs-14 mb-1">
                      {finder.companyName}
                    </p>
                  </li>{" "}
                  <br />
                  <li className="list-inline-item">
                    <p className="text-muted fs-14 mb-0">
                      <i className="mdi mdi-map-marker"></i>{" "}
                      {item.states.join(", ")}
                    </p>
                  </li>
                </ul>

                {item.isActive ? (
                  <>
                    <div className="mt-3 hstack gap-2">
                      <span className="badge rounded-1 badge-soft-success">
                        {item.meeters?.filter(m => m.status !== "deleted")
                          ?.length ?? 0}{" "}
                        Kontaktanfragen
                      </span>
                      <span className="badge rounded-1 badge-soft-danger">
                        {item.meeters?.filter(m => m.status === "deleted")
                          ?.length ?? 0}{" "}
                        Meeter abgelehnt
                      </span>
                    </div>
                    <div className="mt-4">
                      <Link
                        to={"/finder-jobs/" + item.id}
                        className="btn btn-light w-100"
                      >
                        Jobdetails anzeigen
                      </Link>
                      <Link
                        to={"/finder-jobs/" + item.id + "/meeters"}
                        className="btn btn-soft-success w-100 mt-2"
                      >
                        Meeter anzeigen
                      </Link>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="mt-3 hstack gap-2">
                      <span className="badge rounded-1 badge-soft-warning">
                        deaktivierte Anzeige
                      </span>
                    </div>
                    <div className="mt-4">
                      <button disabled className="btn btn-light w-100">
                        Jobdetails anzeigen
                      </button>
                      <button
                        disabled
                        className="btn btn-soft-success w-100 mt-2"
                      >
                        Meeter anzeigen
                      </button>
                    </div>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>

      <Modal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal()
        }}
        id="applyJobs"
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => setDeleteModal()}
            id="applyJobsLabel"
            className="modal-header"
          >
            Jobangebot löschen
          </ModalHeader>
          <ModalBody>
            <Row>
              <h4>Jobangebot löschen!</h4>
              <p>
                Sind Sie sich sicher, dass Sie dieses Jobangebot deaktivieren
                möchten?{" "}
                <span className="text-danger">
                  Dieser Schritt kann nicht mehr rückgängig gemacht werden!{" "}
                </span>
              </p>
              <Col lg={12}>
                <div className="text-end">
                  <button
                    className="btn btn-danger me-1"
                    onClick={() => handleDelete()}
                  >
                    Ja, Angebot löschen
                    <i className=" bx bx-trash align-middle ms-1"></i>
                  </button>
                  <button
                    className="btn btn-outline-secondary"
                    onClick={_ => setDeleteModal(false)}
                  >
                    Abbrechen
                  </button>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default JobData
