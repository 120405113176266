import React, { useEffect, useState } from "react"

import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import "react-datepicker/dist/react-datepicker.css"
import classnames from "classnames"
import { Link, useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { changeLoading } from "store/actions"
import "react-datepicker/dist/react-datepicker.css"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {
  homeOfficeOptions,
  hoursOptions,
  initialJobValues,
  optionsPersonalityJob,
  stateOptions,
  touchedJobValues,
} from "constants/SetupOptions"
import { Austria } from "flatpickr/dist/l10n/at.js"
import {
  addFinderJob,
  getJobById,
  updateFinderJob,
} from "helpers/finder_backend_helper"

const FinderJobCreation = props => {
  const [activeTabVartical, setoggleTabVertical] = useState(1)
  const [job, setJob] = useState(initialJobValues)
  const [touched, setTouched] = useState(touchedJobValues)
  const [error, setError] = useState()
  const dispatch = useDispatch()
  const { id } = useParams()

  useEffect(() => {
    async function getCurrentFinder() {
      dispatch(changeLoading(true))
      const result = await getJobById(id)
      setJob(result)
      dispatch(changeLoading(false))
    }
    if (id) getCurrentFinder()
  }, [])

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      if (tab >= 1 && tab <= 5) {
        setoggleTabVertical(tab)
      }
    }
  }

  function handleStatesChange(selectedOption) {
    let states = ["Ganz Österreich"]
    if (selectedOption !== "Ganz Österreich") {
      states = job.states.filter(s => s !== "Ganz Österreich")

      if (states.includes(selectedOption))
        states = states.filter(s => s !== selectedOption)
      else states = [...states, selectedOption]
    }

    setJob({
      ...job,
      states: states,
    })
  }

  function handleHoursChange(selectedHour) {
    let workingHours = job.workingHours
    if (workingHours.includes(selectedHour))
      workingHours = workingHours.filter(h => h !== selectedHour)
    else workingHours = [...workingHours, selectedHour]

    setJob({
      ...job,
      workingHours: workingHours,
    })
  }

  function handleStrengthChange(e) {
    if (job.strengths.length == 5 && e.target.checked && !error) {
      setError("Die maximale Anzahl ist erreicht.")
      setTimeout(() => {
        setError()
      }, 3000)
    }

    if (job.strengths.length < 5 && e.target.checked)
      setJob({ ...job, strengths: [...job.strengths, e.target.value] })
    else
      setJob({
        ...job,
        strengths: job.strengths.filter(s => s != e.target.value),
      })
  }

  async function handleSubmit() {
    dispatch(changeLoading(true))
    if (id)
      await updateFinderJob({
        ...job,
        finder: job.finder.id,
        lastEdited: new Date(),
      })
    else await addFinderJob(job)
    props.history.push("/finder-jobs")
    dispatch(changeLoading(false))
  }

  function allFieldsAreFilledOut() {
    if (activeTabVartical == 1) {
      if (!job.jobTitle) return false
      if (!job.startDate) return false
      if (job.states.length === 0) return false
      if (job.homeOffice.length === 0) return false
      if (job.workingHours.length === 0) return false
      if (!job.sallery) return false
      return true
    }

    if (activeTabVartical === 2) {
      if (job.strengths.length === 5) return true
      return false
    }

    if (activeTabVartical === 3) {
      if (!job.whyThisJob) return false
      return true
    }
  }

  function containsOnlyNumbers(str) {
    return /^[0-9]+$/.test(str)
  }

  const navItems = [
    "Job Details",
    "Beschreibung Meeter",
    "Was macht diesen Job besonders?",
    "Zusammenfinden",
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Job Erstellung</h4>
                  <div className="vertical-wizard wizard clearfix vertical">
                    <div className="steps clearfix">
                      <ul>
                        {navItems.map((item, idx) => (
                          <NavItem
                            key={idx + 1}
                            className={classnames({
                              current: activeTabVartical === idx + 1,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTabVartical === idx + 1,
                              })}
                              disabled
                            >
                              <span className="number">{idx + 1}.</span> {item}
                            </NavLink>
                          </NavItem>
                        ))}
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent
                        activeTab={activeTabVartical}
                        className="body"
                      >
                        <TabPane tabId={1}>
                          {error ? <Alert color="danger">{error}</Alert> : null}
                          <Form>
                            <Row>
                              <Col lg="6">
                                <FormGroup className="mb-4">
                                  <label className="control-label">
                                    Jobtitel
                                  </label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Jobtitel..."
                                    value={job.jobTitle}
                                    onChange={e =>
                                      setJob({
                                        ...job,
                                        jobTitle: e.target.value,
                                      })
                                    }
                                    onBlur={_ =>
                                      setTouched({ ...touched, jobTitle: true })
                                    }
                                    invalid={!job?.jobTitle && touched.jobTitle}
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-4">
                                  <label className="control-label">
                                    Gewünschtes Eintrittsdatum
                                  </label>
                                  <Flatpickr
                                    className="form-control d-block"
                                    placeholder="dd M,yyyy"
                                    onChange={e =>
                                      setJob({ ...job, startDate: e[0] })
                                    }
                                    value={job?.startDate}
                                    options={{
                                      dateFormat: "d.m.y",
                                      minDate: "today",
                                      locale: {
                                        ...Austria,
                                        firstDayOfWeek: 1,
                                      },
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup className="mb-3">
                                  <div className="mb-3">
                                    <label className="control-label">
                                      In welchem Bundesland ist die offene
                                      Stelle zu besetzen? <br />
                                      (Mehrfachantworten möglich)
                                    </label>
                                    <div>
                                      {stateOptions.map((state, index) => (
                                        <div key={index} className="d-inline">
                                          <label className="m-1">
                                            <input
                                              type="checkbox"
                                              className="card-radio-input"
                                              readOnly
                                              checked={job.states.includes(
                                                state
                                              )}
                                            />
                                            <div
                                              className="card-radio p-2"
                                              onClick={_ =>
                                                handleStatesChange(state)
                                              }
                                            >
                                              <span>{state}</span>
                                            </div>
                                          </label>
                                          {index === 4 && <br />}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <div>
                                    <label className="control-label">
                                      Wie viele Wochenstunden sind für die
                                      offene Stelle vorgesehen? <br />
                                      (Mehrfachantworten möglich)
                                    </label>
                                    <Row>
                                      <div>
                                        {hoursOptions.map((hour, index) => (
                                          <div key={index} className="d-inline">
                                            <label className="m-1">
                                              <input
                                                type="checkbox"
                                                className="card-radio-input"
                                                readOnly
                                                checked={job.workingHours.includes(
                                                  hour
                                                )}
                                              />
                                              <div
                                                className="card-radio p-2"
                                                onClick={_ =>
                                                  handleHoursChange(hour)
                                                }
                                              >
                                                <span>{hour}</span>
                                              </div>
                                            </label>
                                          </div>
                                        ))}
                                      </div>
                                    </Row>
                                  </div>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <div className="mb-3">
                                    <label className="control-label">
                                      Ist Home-Office für die Stelle vorgesehen?
                                    </label>
                                    <Row>
                                      <div>
                                        {homeOfficeOptions.map(
                                          (homeOffice, index) => (
                                            <div
                                              key={index}
                                              className="d-inline"
                                            >
                                              <label className="m-1">
                                                <input
                                                  type="radio"
                                                  className="card-radio-input"
                                                  readOnly
                                                  checked={
                                                    job.homeOffice == homeOffice
                                                  }
                                                />
                                                <div
                                                  className="card-radio p-2"
                                                  onClick={_ =>
                                                    setJob({
                                                      ...job,
                                                      homeOffice: homeOffice,
                                                    })
                                                  }
                                                >
                                                  <span>{homeOffice}</span>
                                                </div>
                                              </label>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </Row>
                                  </div>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <FormGroup className="mb-4">
                                  <label className="control-label">
                                    Jahresbruttogehalt
                                  </label>
                                  <Input
                                    className="form-control"
                                    placeholder="Jahresbruttogehalt..."
                                    type="text"
                                    value={job.sallery}
                                    onChange={e => {
                                      if (
                                        containsOnlyNumbers(e.target.value) ||
                                        e.target.value === ""
                                      )
                                        setJob({
                                          ...job,
                                          sallery: e.target.value,
                                        })
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-4">
                                  <label className="control-label">
                                    Zusatzinfo zum Gehalt (optional)
                                  </label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Bsp.: Bereitschaft zur KV-Überzahlung..."
                                    value={job.salleryInfo}
                                    onChange={e =>
                                      setJob({
                                        ...job,
                                        salleryInfo: e.target.value,
                                      })
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane tabId={2}>
                          <div>
                            <Form>
                              <Row>
                                {error ? (
                                  <Alert color="danger">{error}</Alert>
                                ) : null}
                                <h4>Das muss dem Meeter liegen</h4>
                                <p>
                                  Welche{" "}
                                  <b className="text-primary">
                                    fünf Eigenschaften{" "}
                                  </b>{" "}
                                  setzen Sie voraus, um die/den richtige/n
                                  Mitarbeiter/in für dieses Jobangebot zu
                                  finden.
                                </p>
                              </Row>
                              <Row>
                                <Col lg={6}>
                                  {optionsPersonalityJob
                                    .slice(0, 8)
                                    .map((option, index) => (
                                      <div
                                        className="form-check mb-3"
                                        key={index}
                                      >
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id={"checkbox1" + index}
                                          value={option}
                                          onChange={handleStrengthChange}
                                          checked={job.strengths.includes(
                                            option
                                          )}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={"checkbox1" + index}
                                        >
                                          {option}
                                        </label>
                                      </div>
                                    ))}
                                </Col>
                                <Col lg={6}>
                                  {optionsPersonalityJob
                                    .slice(8, optionsPersonalityJob.length)
                                    .map((option, index) => (
                                      <div
                                        className="form-check mb-3"
                                        key={index}
                                      >
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id={"checkbox" + index}
                                          value={option}
                                          onChange={handleStrengthChange}
                                          checked={job.strengths.includes(
                                            option
                                          )}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={"checkbox" + index}
                                        >
                                          {option}
                                        </label>
                                      </div>
                                    ))}
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        </TabPane>
                        <TabPane tabId={3}>
                          <div>
                            <Row>
                              <Col lg="12">
                                <FormGroup className="mb-3">
                                  <Label className="d-block">
                                    Was macht genau diesen Job so interessant?
                                  </Label>
                                  <textarea
                                    type="text"
                                    rows={5}
                                    className="form-control"
                                    placeholder="Was genau macht diesen Job so besonders?"
                                    value={job.whyThisJob}
                                    maxLength={1000}
                                    onChange={e =>
                                      setJob({
                                        ...job,
                                        whyThisJob: e.target.value,
                                      })
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        </TabPane>
                        <TabPane tabId={4}>
                          <div className="row justify-content-center">
                            <Col lg="6">
                              <div className="text-center">
                                <div className="mb-4">
                                  <i className="mdi mdi-check-circle-outline text-success display-4" />
                                </div>
                                <div>
                                  <h5>Glückwunsch, es ist fast geschafft!</h5>
                                  <p className="text-muted">
                                    Der Job ist nun bereit, um passende Meeter
                                    zu finden. Der Job ist erst nachdem Sie eine
                                    Anfrage bei einem Meeter machen, für diese
                                    Person ersichtlich.
                                  </p>
                                </div>
                              </div>
                            </Col>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>

                    <div className="actions clearfix">
                      <ul>
                        {activeTabVartical != 1 && (
                          <li
                            className={
                              activeTabVartical === 1
                                ? "previous disabled"
                                : "previous"
                            }
                          >
                            <Link
                              to="#"
                              className="btn btn-primary"
                              onClick={() => {
                                toggleTabVertical(activeTabVartical - 1)
                              }}
                            >
                              Zurück
                            </Link>
                          </li>
                        )}
                        {activeTabVartical < 4 ? (
                          <li>
                            <button
                              to="#"
                              className="btn btn-primary"
                              disabled={!allFieldsAreFilledOut()}
                              onClick={() => {
                                toggleTabVertical(activeTabVartical + 1)
                              }}
                            >
                              Weiter
                            </button>
                          </li>
                        ) : (
                          <li>
                            <button
                              className="btn btn-primary"
                              onClick={handleSubmit}
                            >
                              Speichern
                            </button>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FinderJobCreation
