import React from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import { Link } from "react-router-dom"

const JobVacancy = ({ finder }) => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Col lg={12}>
            <div className="d-flex">
              <h4 className="card-title mb-4 flex-grow-1">Jobangebote</h4>
              <div>
                <Link to="/finder-jobs" className="btn btn-primary btn-sm">
                  Alle anzeigen <i className="bx bx-right-arrow-alt"></i>
                </Link>
              </div>
            </div>
          </Col>
          {(!finder.jobs || !finder.jobs?.length > 0) && (
            <p className="text-center mt-4">
              Sie haben derzeit keine Jobangebote aktiv. Sie können im Menüpunkt
              Jobangebot, neue Jobangebote erstellen
            </p>
          )}
          <Row>
            {(finder.jobs?.slice(0, 3) || []).map((item, key) => (
              <Col lg={4} key={key}>
                <Card className="border border-muted">
                  <CardBody className="p-4">
                    <div className="text-center mb-3">
                      {/* <img
                        src={finder.logoURL ?? defaultAvatar}
                        alt=""
                        className="avatar-sm"
                        style={{ objectFit: "cover", borderRadius: "10px" }}
                      /> */}
                      <Link
                        to={"/finder-jobs/" + item.id}
                        className="text-body"
                      >
                        <h5 className="mt-4 mb-2 font-size-15">
                          {item.jobTitle}
                        </h5>
                      </Link>
                      <p className="mb-0 text-muted">{finder.companyName}</p>
                    </div>

                    <div className="d-flex">
                      <p className="mb-0 flex-grow-1 text-center text-muted">
                        <i className="bx bx-map text-body"></i>{" "}
                        {item.states.join(", ")}
                      </p>
                    </div>
                    <div className="mt-3 hstack gap-2 d-flex justify-content-center">
                      <span className="badge rounded-1 badge-soft-success">
                        {item.meeters?.filter(m => m.status !== "deleted")
                          ?.length ?? 0}{" "}
                        Kontaktanfragen
                      </span>
                      <span className="badge rounded-1 badge-soft-danger">
                        {item.meeters?.filter(m => m.status === "deleted")
                          ?.length ?? 0}{" "}
                        Meeter abgelehnt
                      </span>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default JobVacancy
