import React, { useEffect, useState } from "react"
import { Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import Overview from "./Overview"
import DetailsSection from "./DetailsSection"
import { useDispatch } from "react-redux"
import { changeLoading } from "store/actions"
import { useParams } from "react-router-dom"
import { getJobById } from "helpers/finder_backend_helper"

const FinderJobOfferDetail = props => {
  const [job, setJob] = useState({})
  const dispatch = useDispatch()
  const isMobile = screen.width < 1000
  const { id } = useParams()

  useEffect(() => {
    async function getJob() {
      dispatch(changeLoading(true))
      const foundjob = await getJobById(id)
      if (!foundjob.isActive) props.history.push("/404")
      setJob(foundjob)
      dispatch(changeLoading(false))
    }
    getJob()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Jobangebote" breadcrumbItem="Details" />

          {isMobile ? (
            <Row>
              <DetailsSection job={job} />
              <Overview job={job} history={props.history} />
            </Row>
          ) : (
            <Row>
              <Overview job={job} history={props.history} />
              <DetailsSection job={job} />
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FinderJobOfferDetail
