import React from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import { Link } from "react-router-dom"

//import images
import defaultAvatar from "../../assets/images/finder/default-avatar.png"

const JobData = ({ jobs, filterOption }) => {
  return (
    <React.Fragment>
      <Row>
        {(!jobs || !jobs?.length > 0) && (
          <p className="text-center mt-4">
            {filterOption === "open"
              ? "Gratulation - du nimmst dein Berufsleben selber in die Hand! Bitte habe noch ein wenig Geduld, bis die Datenbank ausreichend gefüllt ist - vielen Dank."
              : filterOption === "accepted"
              ? "Du hast noch keine Jobangebote bestätigt."
              : "Du hast noch keine Jobangebote abgelehnt."}
          </p>
        )}
        {jobs?.map((item, key) => (
          <Col xl={3} md={6} key={key}>
            <Card>
              <CardBody className="text-center">
                {/* <img
                  src={item.finder.logoURL ?? defaultAvatar}
                  alt=""
                  height="60"
                  width="60"
                  className="mb-3"
                  style={{ objectFit: "cover", borderRadius: "10px" }}
                /> */}
                {item?.finder?.logoURL ? (
                  <img
                    src={item.finder.logoURL}
                    alt=""
                    width="100"
                    className="mb-3"
                    style={{ height: "fit-content", margin: "auto" }}
                  />
                ) : (
                  <img
                    src={defaultAvatar}
                    alt=""
                    width="60"
                    height="60"
                    className="mb-3"
                    style={{ objectFit: "cover", borderRadius: "10px" }}
                  />
                )}
                <h5 className="fs-17 mb-2">
                  <Link
                    to={
                      filterOption !== "declined"
                        ? "/jobangebote/" + item.id
                        : "#"
                    }
                    className="text-dark"
                  >
                    {item.jobTitle}
                  </Link>{" "}
                </h5>
                <ul className="list-inline mb-0">
                  <li className="list-inline-item">
                    <p className="text-muted fs-14 mb-1">
                      {item.finder.companyName}
                    </p>
                  </li>{" "}
                  <br />
                  <li className="list-inline-item">
                    <p className="text-muted fs-14 mb-0">
                      <i className="mdi mdi-map-marker"></i>{" "}
                      {item.states.join(", ")}
                    </p>
                  </li>
                </ul>
                {/* <div className="mt-3 hstack gap-2">
                  <span className="badge rounded-1 badge-soft-success">
                    {item.workingHours.join(", ")}
                  </span>
                  <span className="badge rounded-1 badge-soft-warning">
                    {item.homeOffice.join(", ")}
                  </span>
                </div> */}
                {filterOption !== "declined" && (
                  <div className="mt-4 hstack gap-2">
                    <Link
                      to={"/jobangebote/" + item.id}
                      className="btn btn-soft-primary w-100"
                    >
                      Finder anzeigen
                    </Link>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  )
}

export default JobData
