import React from "react"
import { Card, CardBody, Col } from "reactstrap"
import { Link } from "react-router-dom"
import defaultAvatar from "../../assets/images/finder/default-avatar.png"

const JobVacancy = ({ jobs }) => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Col lg={12}>
            <div className="d-flex">
              <h4 className="card-title mb-4 flex-grow-1">
                Offene Jobangebote
              </h4>
              <div>
                <Link to="/jobangebote" className="btn btn-primary btn-sm">
                  Alle anzeigen <i className="bx bx-right-arrow-alt"></i>
                </Link>
              </div>
            </div>
          </Col>
          {(!jobs || !jobs?.length > 0) && (
            <p className="text-center mt-4">
              Wir informieren dich, sobald sich ein Unternehmen für dich
              interessiert.
            </p>
          )}
          {(jobs?.slice(0, 3) || []).map((item, key) => (
            <Col lg={4} key={key}>
              <Card className="border border-muted">
                <CardBody className="p-4">
                  <div className="text-center mb-3">
                    {item?.finder?.logoURL ? (
                      <img
                        src={item.finder.logoURL}
                        alt=""
                        width="100"
                        style={{ height: "fit-content", margin: "auto" }}
                      />
                    ) : (
                      <img
                        src={defaultAvatar}
                        alt=""
                        width="60"
                        height="60"
                        style={{ objectFit: "cover", borderRadius: "10px" }}
                      />
                    )}
                    <Link to={"/jobangebote/" + item.id} className="text-body">
                      <h5 className="mt-4 mb-2 font-size-15">
                        {item.jobTitle}
                      </h5>
                    </Link>
                    <p className="mb-0 text-muted">{item.finder.companyName}</p>
                  </div>

                  <div className="d-flex">
                    <p className="mb-0 flex-grow-1 text-center text-muted">
                      <i className="bx bx-map text-body"></i>{" "}
                      {item.states.join(", ")}
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default JobVacancy
