import React, { useState } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { changeLoading } from "../../store/actions"

//redux
import { useDispatch } from "react-redux"

import { Link } from "react-router-dom"

// import images
import profileImg from "../../assets/images/profile-img.png"
import logoImg from "../../assets/images/logo.svg"
import { signup } from "helpers/auth_backend_helper"

const Register = props => {
  const [error, setError] = useState(false)
  const dispatch = useDispatch()

  const handleSubmit = async values => {
    try {
      dispatch(changeLoading(true))
      const response = await signup(values)
      localStorage.setItem("token", response.token)
      localStorage.setItem("email", values.email)
      props.history.push("/email-verification")
      dispatch(changeLoading(false))
    } catch (error) {
      displayError(error)
      dispatch(changeLoading(false))
    }
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Bitte E-Mail eintragen"),
      password: Yup.string().required("Bitte Passwort eintragen"),
    }),
    onSubmit: handleSubmit,
  })

  const displayError = message => {
    setError(message)
    setTimeout(() => {
      setError()
    }, 3000)
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link
          to="#"
          onClick={_ => (window.location.href = "https://meet2find.at")}
          className="text-dark"
        >
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={6}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">
                          Account kostenlos registrieren
                        </h5>
                        <p>Jetzt Account kostenlos registrieren</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="50"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {error && <Alert color="danger">{error}</Alert>}

                      <div className="mb-3">
                        <Label className="form-label">E-Mail</Label>
                        <Input
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="E-Mail"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Passwort</Label>
                        <Input
                          name="password"
                          type="password"
                          placeholder="Passwort (min 6 Zeichen)"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="form-check mt-3">
                        <Input
                          type="checkbox"
                          className="form-check-input"
                          id="dsgvo"
                          required
                        />
                        <label htmlFor="dsgvo" className="form-check-label">
                          Ich habe die{" "}
                          <a
                            href="https://meet2find.at/datenschutz"
                            className="text-primary"
                            target={"_blank"}
                            rel="noreferrer"
                          >
                            Datenschutzrichlinien
                          </a>{" "}
                          gelesen
                        </label>
                      </div>

                      <div className="form-check mt-3">
                        <Input
                          type="checkbox"
                          className="form-check-input"
                          id="legalnotice"
                          required
                        />
                        <label
                          htmlFor="legalnotice"
                          className="form-check-label"
                        >
                          Ich habe die{" "}
                          <a
                            href="https://meet2find.at/wp-content/uploads/2023/01/Nutzungsbedingungen.pdf"
                            className="text-primary"
                            target={"_blank"}
                            rel="noreferrer"
                          >
                            Nutzungsbedingungen
                          </a>{" "}
                          gelesen und akzeptiere diese.
                        </label>
                      </div>

                      <div className="mt-4">
                        <button
                          className="btn btn-primary btn-block me-auto ms-auto w-100"
                          type="submit"
                        >
                          Registrieren
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-2 mb-5 text-center">
                <p>
                  Sie haben schon einen Account ?{" "}
                  <Link to="login" className="fw-medium text-primary">
                    {" "}
                    Jetzt anmelden
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Register
