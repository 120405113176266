import React, { useState, useEffect } from "react"
import { Link, withRouter } from "react-router-dom"
import { map } from "lodash"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import MiniCards from "./mini-card"
import profile1 from "assets/images/profile-img.png"
import defaultAvatar from "assets/images/logo-small.png"
import { useDispatch } from "react-redux"
import { changeLoading } from "store/actions"
import JobVacancy from "./JobVacancy"
import { getFinderJobs } from "helpers/finder_backend_helper"

const DashboardMeeter = props => {
  const [finder, setFinder] = useState()
  const dispatch = useDispatch()
  const isMobile = screen.width < 1000

  const [miniCards, setMiniCards] = useState([
    {
      title: "offene Jobs",
      iconClass: "bx bx-list-ul",
      text: "0",
    },
    { title: "Offene Anfrage", iconClass: "bx-hourglass", text: "0" },
    {
      title: "Bestätigte Anfragen",
      iconClass: "bx bxs-badge-check",
      text: "0",
    },
  ])

  useEffect(() => {
    async function getCurrentFinder() {
      dispatch(changeLoading(true))
      const result = await getFinderJobs()
      if (result) result.jobs = result.jobs.filter(j => j.isActive)
      setFinder(result)
      dispatch(changeLoading(false))
    }
    getCurrentFinder()
  }, [])

  useEffect(() => {
    if (finder)
      setMiniCards([
        {
          title: "Offene Jobs",
          iconClass: "bx bx-list-ul",
          text: "" + finder?.jobs.length ?? "0",
        },
        {
          title: "Offene Anfrage",
          iconClass: "bx-hourglass",
          text: "" + getJobOffersByStatus("open"),
        },
        {
          title: "Bestätigte Anfragen",
          iconClass: " bx bxs-badge-check",
          text: "" + getJobOffersByStatus("accepted"),
        },
      ])
  }, [finder])

  const getJobOffersByStatus = status => {
    let count = 0
    finder.jobs.forEach(job => {
      count += job.meeters?.filter(m => m.status === status).length ?? 0
    })

    return count
  }

  const mapCompanySize = idx => {
    const sizes = process.env.REACT_APP_COMPANY_SIZES?.split(",")
    return sizes[idx - 1]
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Finder" breadcrumbItem="Profilübersicht" />

          {finder && (
            <Row>
              <Col xl="4">
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col xs="8">
                        <div className="text-primary p-3">
                          <h5 className="text-primary">Herzlich willkommen!</h5>
                          <p>Schön, dass Sie da sind :)</p>
                        </div>
                      </Col>
                      <Col xs="4" className="align-self-end">
                        <img src={profile1} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <Row>
                      <Col sm="6">
                        <div className="avatar-md profile-user-wid mb-4">
                          <img
                            src={defaultAvatar}
                            alt=""
                            className="img-thumbnail rounded-circle"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <h5 className="font-size-15 text-truncate">
                          {finder.companyName}
                        </h5>
                        <p className="text-muted mb-0 text-truncate">
                          {finder.field}
                        </p>
                      </Col>

                      <Col sm={6} align="right">
                        <div className="pt-4">
                          <Link
                            to="/finder-bearbeiten"
                            className="btn btn-primary  btn-sm"
                          >
                            Profil ändern{" "}
                            <i className="mdi mdi-arrow-right ms-1" />
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Finder-Profil:</CardTitle>
                    <div className="table-responsive">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">Firmengröße :</th>
                            <td>
                              {mapCompanySize(finder.companySize)} Mitarbeiter
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Kontaktperson :</th>
                            <td>{finder.contactPerson}</td>
                          </tr>
                          <tr>
                            <th scope="row">UID-Nummer :</th>
                            <td>{finder.vatNumber}</td>
                          </tr>
                          <tr>
                            <th scope="row">Firmenbuch-Nummer :</th>
                            <td>{finder.companyNr}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl="8">
                {!isMobile && (
                  <Row>
                    {map(miniCards, (card, key) => (
                      <MiniCards
                        title={card.title}
                        text={card.text}
                        iconClass={card.iconClass}
                        key={"_card_" + key}
                      />
                    ))}
                  </Row>
                )}
                <JobVacancy finder={finder} />
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(DashboardMeeter)
