import React from "react"
import { Card, CardBody, Col } from "reactstrap"

//import images
import defaultAvatar from "../../assets/images/finder/default-avatar.png"

const DetailsSection = ({ job }) => {
  return (
    <React.Fragment>
      <Col xl={9}>
        <Card>
          <CardBody className="border-bottom">
            <div className="d-flex">
              {job?.finder?.logoURL ? (
                <img
                  src={job.finder.logoURL}
                  alt=""
                  width="100"
                  style={{ height: "fit-content", margin: "auto" }}
                />
              ) : (
                <img
                  src={defaultAvatar}
                  alt=""
                  width="60"
                  height="60"
                  style={{ objectFit: "cover", borderRadius: "10px" }}
                />
              )}
              <div className="flex-grow-1 ms-3 d-flex flex-column justify-content-center">
                <h5 className="fw-semibold">{job?.jobTitle}</h5>
                <ul className="list-unstyled hstack gap-2 mb-0">
                  <li>
                    <i className="bx bx-building-house"></i>{" "}
                    <span className="text-muted">
                      {job?.finder?.companyName}
                    </span>
                  </li>
                  <li>
                    <i className="bx bx-map"></i>{" "}
                    <span className="text-muted">
                      {job?.states?.join(", ")}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </CardBody>
          <CardBody>
            <ul className="verti-timeline list-unstyled">
              <li className="event-list">
                <div className="event-timeline-dot">
                  <i className="bx bx-right-arrow-circle"></i>
                </div>
                <div>
                  <h4 className="font-size-14 mb-1">
                    Warum soll jemand bei Ihnen arbeiten wollen?
                  </h4>

                  <p className="text-muted mb-0">{job?.finder?.whyUs}</p>
                </div>
              </li>
              <li className="event-list">
                <div className="event-timeline-dot">
                  <i className="bx bx-right-arrow-circle"></i>
                </div>
                <div>
                  <h4 className="font-size-14 mb-1">Warum dieser Job?</h4>

                  <p className="text-muted mb-0">{job?.whyThisJob}</p>
                </div>
              </li>
            </ul>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default DetailsSection
