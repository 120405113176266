import React, { useEffect, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
} from "reactstrap"
import classnames from "classnames"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import { changeLoading } from "store/actions"
import {
  createFinder,
  updateFinder,
  checkCurrentFinder,
} from "helpers/finder_backend_helper"
import GeneralInfosPane from "./Panes/GeneralInfosPane"
import {
  initialFinderValues,
  touchedFinderValues,
} from "constants/SetupOptions"
import WhyUsPane from "./Panes/WhyUsPane"
import PaymentPane from "./Panes/PaymentPane"
import ConfirmationPane from "./Panes/ConfirmationPane"
import PromoCodeModal from "./Panes/PromoCodeModal"
import { sendAboConfirmationFinder } from "helpers/mail_helper"
import { getCustomerNumber } from "helpers/stripe_backend_helper"

const FinderSetup = props => {
  const userEmail = localStorage.getItem("email")
  const query = new URLSearchParams(window.location.search)
  const [finder, setFinder] = useState(initialFinderValues)
  const [touched, setTouched] = useState(touchedFinderValues)
  const [error, setError] = useState(false)
  const [invoiceCreated, setInvoiceCreated] = useState(false)
  const [activeTabVartical, setoggleTabVertical] = useState(1)
  const dispatch = useDispatch()
  const navItems = [
    "Generelle Infos",
    "Was zeichnet uns aus?",
    "Abschließen & Bezahlen",
    "Zusammenfinden",
  ]

  useEffect(() => {
    async function setup() {
      dispatch(changeLoading(true))

      const current = await checkCurrentFinder()
      if (current) setFinder(current)

      //todo make check for valid payment
      if (query.get("success")) finalizeFinder()
      else {
        if (query.get("canceled")) setoggleTabVertical(3)

        dispatch(changeLoading(false))
      }
    }
    setup()
  }, [])

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      if (tab >= 1 && tab <= 4) {
        setoggleTabVertical(tab)
      }
    }
    setTimeout(() => {
      scrollTo(0, 0)
    }, 200)
  }
  function allFieldsAreFilledOut() {
    if (activeTabVartical === 1) {
      setTouched({
        ...touched,
        companyName: true,
        contactPerson: true,
        vatNumber: true,
        companyNr: true,
        street: true,
        city: true,
        field: true,
        postalCode: true,
      })

      if (!finder.companyName) return false
      if (!finder.contactPerson) return false
      if (!finder.vatNumber) return false
      if (!finder.companyNr) return false
      if (!finder.field) return false
      if (!finder.companySize) return false
      if (!finder.street) return false
      if (!finder.city) return false
      if (!finder.postalCode) return false
      if (!finder.gender) return false
    } else {
      if (!finder.whyUs) return false
    }

    return true
  }

  function toggleTab() {
    if (allFieldsAreFilledOut())
      if (activeTabVartical === 2) handleSubmit()
      else toggleTabVertical(activeTabVartical + 1)
    else {
      setError(true)
      setTimeout(() => {
        setError(false)
      }, 3000)
    }
  }

  async function handleSubmit() {
    dispatch(changeLoading(true))
    finder.email = userEmail

    const maxJobs = mapSizeToJobsCount(finder.companySize)
    const newFinder = {
      ...finder,
      maxJobs,
    }

    await createFinder(newFinder)
    setFinder(newFinder)
    toggleTabVertical(activeTabVartical + 1)
    dispatch(changeLoading(false))
  }

  function mapSizeToJobsCount(companySize) {
    switch (companySize) {
      case "0":
        return 1
      case "1":
        return 2
      case "2":
        return 3
      case "3":
        return 4
      default:
        return 1
    }
  }

  async function finalizeFinder() {
    let current = await checkCurrentFinder()
    const customer = await getCustomerNumber(userEmail)
    current = {
      ...current,
      customerID: customer.id,
      createdAt: new Date(),
      isActive: true,
    }
    await updateFinder(current)
    await sendAboConfirmationFinder(userEmail)
    setFinder(current)
    toggleTabVertical(4)
    dispatch(changeLoading(false))
  }

  return (
    <React.Fragment>
      <div
        className="page-content bg-primary bg-soft"
        style={{ minHeight: "850px" }}
      >
        <Container fluid={true}>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4" id="heading">
                    Profil erstellen
                  </h4>
                  <div className="vertical-wizard wizard clearfix vertical">
                    <div className="steps clearfix">
                      <ul>
                        {navItems.map((item, idx) => (
                          <NavItem
                            key={idx + 1}
                            className={classnames({
                              current: activeTabVartical === idx + 1,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTabVartical === idx + 1,
                              })}
                              disabled
                            >
                              <span className="number">{idx + 1}.</span> {item}
                            </NavLink>
                          </NavItem>
                        ))}
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent
                        activeTab={activeTabVartical}
                        className="body"
                      >
                        <GeneralInfosPane
                          finder={finder}
                          setFinder={setFinder}
                          touched={touched}
                          setTouched={setTouched}
                        />

                        <WhyUsPane
                          finder={finder}
                          setFinder={setFinder}
                          touched={touched}
                          setTouched={setTouched}
                        />

                        <PaymentPane
                          finder={finder}
                          userEmail={userEmail}
                          activeTabVartical={activeTabVartical}
                          toggleTabVertical={toggleTabVertical}
                          setoggleTabVertical={setoggleTabVertical}
                          setInvoiceCreated={setInvoiceCreated}
                          finalizeFinder={finalizeFinder}
                        />
                        {activeTabVartical === 3 && <PromoCodeModal />}

                        <ConfirmationPane invoiceCreated={invoiceCreated} />
                      </TabContent>
                    </div>

                    <div className="actions clearfix">
                      <ul>
                        {activeTabVartical < 3 && (
                          <>
                            {error && (
                              <li>
                                <p className="text-danger">
                                  Es müssen alle Pflichtfelder befüllt sein
                                </p>
                              </li>
                            )}
                            <li
                              className={
                                activeTabVartical === 1
                                  ? "previous disabled"
                                  : "previous"
                              }
                            >
                              <Link
                                to="#"
                                className="btn"
                                onClick={() => {
                                  toggleTabVertical(activeTabVartical - 1)
                                }}
                              >
                                Zurück
                              </Link>
                            </li>
                            <li>
                              <button
                                className="next btn btn-primary"
                                onClick={toggleTab}
                              >
                                {activeTabVartical === 2
                                  ? "Speichern"
                                  : "Weiter"}
                              </button>
                            </li>
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FinderSetup
