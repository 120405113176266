import React from "react"

import { TabPane } from "reactstrap"
import { Link } from "react-router-dom"

const ConfirmationPane = ({ invoiceCreated }) => {
  return (
    <TabPane tabId={4}>
      <div className="row justify-content-center">
        <div className="text-center">
          <div>
            <div className="mb-4">
              <i className="mdi mdi-check-circle-outline text-success display-4" />
            </div>
            {invoiceCreated ? (
              <>
                <h4>
                  Geschafft! Sie können jetzt Jobs erstellen und neue
                  Mitarbeiter:innen finden.
                </h4>
                <p className="text-muted mt-2 mb-0">
                  Die Rechnung wird Ihnen per Mail übermittelt. Vielen Dank!{" "}
                </p>
                <p className="text-muted">
                  Einfach auf den Button klicken und los geht`s!
                </p>
              </>
            ) : (
              <>
                <h4 style={{ maxWidth: "400px" }} className="mx-auto">
                  Geschafft! Sie können jetzt Jobs erstellen und neue
                  Mitarbeiter:innen finden.
                </h4>
                <p className="text-muted">
                  Einfach auf den Button klicken und los geht`s!
                </p>
              </>
            )}
            <Link to="/finder-dashboard" className="btn btn-primary">
              Jetzt starten
            </Link>
          </div>
        </div>
      </div>
    </TabPane>
  )
}

export default ConfirmationPane
