import { verifyEmail } from "helpers/auth_backend_helper"
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { changeLoading } from "store/actions"
import logodark from "../../assets/images/logo-dark.png"

const ConfirmMail = () => {
  const query = new URLSearchParams(window.location.search)
  const userType = query.get("userType")
  const { email } = useParams()
  const dispatch = useDispatch()

  const verify = async _ => {
    dispatch(changeLoading(true))
    await verifyEmail(email)

    dispatch(changeLoading(false))
  }

  useEffect(() => {
    verify()
  }, [email])

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mb-5 text-muted">
                <img
                  src={logodark}
                  alt=""
                  height="60"
                  className="auth-logo-dark mx-auto"
                />
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <div className="p-2">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bx-mail-send h1 mb-0 text-primary"></i>
                        </div>
                      </div>
                      <div className="p-2 mt-4">
                        <h4>Bestätigung !</h4>
                        <p className="text-muted">
                          Vielen Dank für die Bestätigung Ihrer E-Mail. Sie
                          können nun Ihr Profil ausfüllen.
                        </p>
                        <div className="mt-4">
                          <Link
                            to={
                              userType === "meeter"
                                ? "/meeter-setup"
                                : userType === "finder"
                                ? "/finder-setup"
                                : "/account-auswahl"
                            }
                            className="btn btn-success"
                          >
                            Profil erstellen
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ConfirmMail
