import React from "react"
import { Card, CardBody, Col, Container, Row, Input } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import List from "./List"

const MeeterList = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Jobs" breadcrumbItem="Meeter-Liste" />

          {/* <Row>
            <Col lg={12}>
              <Card className="job-filter">
                <CardBody>
                  <form action="#">
                    <Row className="g-3">
                      <Col xxl={4} lg={4}>
                        <div className="position-relative">
                          <Input
                            type="text"
                            className="form-control"
                            id="searchJob"
                            autoComplete="off"
                            placeholder="Suche..."
                          />
                        </div>
                      </Col>

                      <Col xxl={2} lg={6}>
                        <div className="position-relative h-100 hstack gap-3">
                          <button
                            type="submit"
                            className="btn btn-primary h-100 "
                          >
                            <i className="bx bx-search-alt align-middle"></i>{" "}
                            Suche
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
          <List />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MeeterList
