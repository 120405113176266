import PropTypes from "prop-types"
import React, { useState } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"
import { useDispatch } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"
import { changeLoading } from "../../store/actions"
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"
import { login } from "helpers/auth_backend_helper"

const Login = props => {
  const dispatch = useDispatch()
  const [error, setError] = useState()

  const handleSubmit = async values => {
    try {
      dispatch(changeLoading(true))
      const result = await login(values)

      redirectToDashboard(result)
    } catch (error) {
      console.log(error)
      displayError(error)
      dispatch(changeLoading(false))
    }
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Bitte E-Mail eintragen"),
      password: Yup.string().required("Bitte Passwort eintragen"),
    }),
    onSubmit: handleSubmit,
  })

  const redirectToDashboard = result => {
    if (!result.isVerified)
      return (window.location.href = "/email-verification")

    switch (result.userType) {
      case "meeter":
        window.location.href = "/dashboard"
        break
      case "finder":
        window.location.href = "/finder-dashboard"
        break
      default:
        window.location.href = "/account-auswahl"
        break
    }
  }

  const displayError = message => {
    setError(message)
    setTimeout(() => {
      setError()
    }, 3000)
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link
          to="#"
          onClick={_ => (window.location.href = "https://meet2find.at")}
          className="text-dark"
        >
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Willkommen zurück !</h5>
                        <p>Melden Sie sich an</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="50"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label">E-Mail</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="email@meet2find.at"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Bitte Passwort eingeben"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Anmelden
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/passwort-vergessen" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Passwort vergessen ?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Noch keinen Account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    Bitte hier registrieren
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
