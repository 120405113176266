import React, { useState, useEffect } from "react"
import { Link, withRouter } from "react-router-dom"
import { map } from "lodash"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import MiniCards from "./mini-card"
import profile1 from "assets/images/profile-img.png"
import defaultAvatar from "assets/images/logo-small.png"
import { changeLoading } from "store/actions"
import { useDispatch } from "react-redux"
import JobVacancy from "./JobVacancy"
import { getMeeterDasboardData } from "helpers/meeter_backend_helper"

const DashboardMeeter = () => {
  const dispatch = useDispatch()
  const [meeter, setMeeter] = useState({})
  const isMobile = screen.width < 1000

  useEffect(() => {
    async function setup() {
      dispatch(changeLoading(true))
      const result = await getMeeterDasboardData()
      setMeeter(result)

      dispatch(changeLoading(false))
    }
    setup()
  }, [])

  useEffect(() => {
    setStatistic()
  }, [meeter])

  const [miniCards, setMiniCards] = useState([
    {
      title: "Bestätigte Anfragen",
      iconClass: "bx-check-circle",
      text: "0",
    },
    { title: "Offene Anfragen", iconClass: "bx-x-circle", text: "0" },
    { title: "Anfragen gesamt", iconClass: " bx-info-circle", text: "0" },
  ])

  const setStatistic = () => {
    setMiniCards([
      {
        title: "Anfragen gesamt",
        iconClass: " bx-info-circle",
        text:
          "" +
          ((meeter?.acceptedJobOffersCount ?? 0) +
            (meeter?.declinedJobOffersCount ?? 0) +
            (meeter?.openJobOffers?.length ?? 0)),
      },
      {
        title: "Bestätigte Anfragen",
        iconClass: "bx-check-circle",
        text: "" + (meeter?.acceptedJobOffersCount ?? 0),
      },
      {
        title: "Abgelehnte Anfragen",
        iconClass: "bx-x-circle",
        text: "" + (meeter?.declinedJobOffersCount ?? 0),
      },
    ])
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Meeter" breadcrumbItem="Profilübersicht" />

          <Row>
            <Col xl="4">
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs="8">
                      <div className="text-primary p-3">
                        <h5 className="text-primary">Willkommen zurück!</h5>
                        <p>Schön, dass du da bist! :)</p>
                      </div>
                    </Col>
                    <Col xs="4" className="align-self-end">
                      <img src={profile1} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <Row>
                    <Col sm="6">
                      <div className="avatar-md profile-user-wid mb-4">
                        <img
                          src={defaultAvatar}
                          alt=""
                          className="img-thumbnail rounded-circle"
                        />
                      </div>
                      <h5 className="font-size-15 text-truncate">
                        {meeter?.firstname + " " + meeter?.lastname}
                      </h5>
                    </Col>

                    <Col sm={6} align="right">
                      <div className="pt-4">
                        <Link
                          to="/meeter-bearbeiten"
                          className="btn btn-primary  btn-sm"
                        >
                          Profil ändern{" "}
                          <i className="mdi mdi-arrow-right ms-1" />
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Dein Meeter-Profil:</CardTitle>
                  <p className="text-muted mb-4">{meeter?.personalDetail}</p>
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">Gewünschter Arbeitsort :</th>
                          <td>{meeter?.states?.join(", ")}</td>
                        </tr>
                        <tr>
                          <th scope="row">Alter :</th>
                          <td>{meeter?.age}</td>
                        </tr>
                        <tr>
                          <th scope="row">Stunden / Woche :</th>
                          <td>{meeter?.workingHours?.join(", ")}</td>
                        </tr>
                        <tr>
                          <th scope="row">Homeoffice :</th>
                          <td>{meeter?.homeOffice}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl="8">
              {!isMobile && (
                <Row>
                  {map(miniCards, (card, key) => (
                    <MiniCards
                      title={card.title}
                      text={card.text}
                      iconClass={card.iconClass}
                      key={"_card_" + key}
                    />
                  ))}
                </Row>
              )}
              <JobVacancy jobs={meeter.openJobOffers} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(DashboardMeeter)
