import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import LogoOnlyLayout from "components/LogoOnlyLayout"
import EmptyLayout from "components/EmptyLayout"

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  hasLayout,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (isAuthProtected && !localStorage.getItem("token")) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }

      if (hasLayout)
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )

      if (hasLayout === false)
        return (
          <LogoOnlyLayout>
            <Component {...props} />
          </LogoOnlyLayout>
        )
      return (
        <EmptyLayout>
          <Component {...props} />
        </EmptyLayout>
      )
    }}
  />
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware
