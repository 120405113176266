import React from "react"
const ThemeFinder = React.lazy(() => import("./ThemeFinder"))
const ThemeMeeter = React.lazy(() => import("./ThemeMeeter"))

const Theme = ({ children }) => {
  const userType = localStorage.getItem("userType")

  return (
    <>
      <React.Suspense fallback={<></>}>
        {userType === "finder" ? (
          <>
            <ThemeFinder />
            {children}
          </>
        ) : (
          <>
            <ThemeMeeter />
            {children}
          </>
        )}
      </React.Suspense>
    </>
  )
}

export default Theme
