import React from "react"

import { TabPane } from "reactstrap"

import { Link } from "react-router-dom"

const ConfirmationPane = () => {
  return (
    <TabPane tabId={4}>
      <div className="row justify-content-center">
        <div className="text-center">
          <div>
            <div className="mb-4">
              <i className="mdi mdi-check-circle-outline text-success display-4" />
            </div>
            <h4>Geschafft! Du bist jetzt bereit für deine Finder!</h4>
            <p className="text-muted">
              Du erhälst eine Mail sobald ein Finder dich kontaktieren möchte.
            </p>
            <Link to="/dashboard" className="btn btn-primary">
              Jetzt starten
            </Link>
          </div>
        </div>
      </div>
    </TabPane>
  )
}

export default ConfirmationPane
