import React from "react"

import { Col, FormGroup, Label, Row, TabPane } from "reactstrap"

const WhyUsPane = ({ finder, setFinder, touched, setTouched }) => {
  return (
    <TabPane tabId={2}>
      <div>
        <Row>
          <Col lg="12">
            <FormGroup className="mb-3">
              <Label className="d-block">
                Was zeichnet Ihr Unternehmen aus?
              </Label>
              <textarea
                type="text"
                rows={5}
                className="form-control"
                placeholder="Beschreiben Sie in wenigen Sätzen, was Ihr Unternehmen besonders auszeichnet ..."
                value={finder.whyUs}
                maxLength={1000}
                onChange={e =>
                  setFinder({
                    ...finder,
                    whyUs: e.target.value,
                  })
                }
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    </TabPane>
  )
}

export default WhyUsPane
