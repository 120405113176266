import PropTypes from "prop-types"
import React, { useEffect } from "react"

import { Switch, BrowserRouter as Router } from "react-router-dom"
import { connect } from "react-redux"

// Import Routes all
import {
  authProtectedRoutes,
  authProtectedRoutesWithoutLayout,
  publicRoutes,
} from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

import Pages404 from "pages/Utility/pages-404"
import Theme from "themes/Theme"

const App = props => {
  function getLayout() {
    return HorizontalLayout
  }

  const Layout = getLayout()
  return (
    <React.Fragment>
      <Theme>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                hasLayout={true}
                exact
              />
            ))}

            {authProtectedRoutesWithoutLayout.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                hasLayout={false}
                exact
              />
            ))}

            <Authmiddleware
              path="*"
              layout={NonAuthLayout}
              component={Pages404}
              isAuthProtected={false}
              exact
            />
          </Switch>
        </Router>
      </Theme>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
