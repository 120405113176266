import React, { useState } from "react"

import { Alert, Col, Row, TabPane } from "reactstrap"

import { optionsPersonalityMeeter } from "../../../constants/SetupOptions"

const StrengthsPane = ({ meeter, setMeeter }) => {
  const [error, setError] = useState(false)

  function handleStrengthChange(e) {
    let selectedStrengths = meeter.strengths

    if (selectedStrengths.length == 5 && e.target.checked && !error) {
      setError("Es können nur maximal 5 Optionen ausgewählt werden.")
      setTimeout(() => {
        setError()
      }, 3000)
    }

    if (selectedStrengths.length < 5 && e.target.checked)
      selectedStrengths = [...selectedStrengths, e.target.value]
    else selectedStrengths = selectedStrengths.filter(s => s != e.target.value)

    setMeeter({
      ...meeter,
      strengths: selectedStrengths,
    })
  }
  return (
    <TabPane tabId={2}>
      <div>
        <Row>
          {error ? <Alert color="danger">{error}</Alert> : null}

          <h4>Das macht mir Freude und liegt mir ganz besonders:</h4>
          <p>
            Bitte kreuze min. 3 bis maximal 5 Optionen an, mit denen du dich
            identifizieren kannst.{" "}
          </p>
        </Row>
        <br />
        <Row>
          <Col lg={6}>
            {optionsPersonalityMeeter.slice(0, 8).map((option, index) => (
              <div className="form-check mb-3" key={index}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={"checkbox1" + index}
                  value={option}
                  onChange={handleStrengthChange}
                  checked={meeter.strengths.includes(option)}
                />
                <label
                  className="form-check-label"
                  htmlFor={"checkbox1" + index}
                >
                  {option}
                </label>
              </div>
            ))}
          </Col>
          <Col lg={6}>
            {optionsPersonalityMeeter.slice(8, 15).map((option, index) => (
              <div className="form-check mb-3" key={index}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={"checkbox" + index}
                  value={option}
                  onChange={handleStrengthChange}
                  checked={meeter.strengths.includes(option)}
                />
                <label
                  className="form-check-label"
                  htmlFor={"checkbox" + index}
                >
                  {option}
                </label>
              </div>
            ))}
          </Col>
        </Row>
      </div>
    </TabPane>
  )
}

export default StrengthsPane
