import { get, post } from "./api_helper"

export async function createSubscription(priceID, finder) {
  return await post("/stripe/create-finder-invoice/" + priceID, {
    email: finder.email,
    name: finder.companyName,
    street: finder.street,
    postalCode: finder.postalCode,
    city: finder.city,
    country: "AT",
  })
}

export async function createSubscriptionMonthly(priceID, finder) {
  return await post("/stripe/create-finder-invoice-monthly/" + priceID, {
    email: finder.email,
    name: finder.companyName,
    street: finder.street,
    postalCode: finder.postalCode,
    city: finder.city,
    country: "AT",
  })
}

export async function openStripeCheckout(priceID, email) {
  return await post("/stripe/create-finder-checkout/" + priceID, {
    email,
  })
}

export async function cancelFinderJobSubscription(email) {
  return await post("/stripe/cancel-job-subscription/" + email)
}

export async function getCustomerNumber(email) {
  try {
    return await get(`/stripe/getCustomerID/${email}`)
  } catch (error) {}
}
