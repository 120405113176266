import { del, get, handleError, post, put } from "./api_helper"

export async function createMeeter(meeter) {
  try {
    await post(`/meeters/create`, meeter)
  } catch (error) {
    handleError(error)
  }
}

export async function updateMeeter(meeter) {
  try {
    await put(`/meeters/update`, meeter)
  } catch (error) {
    handleError(error)
  }
}

export async function getCurrentMeeter() {
  try {
    const result = await get(`/meeters/getCurrentMeeter`)

    if (!result?.isActive)
      return (window.location.href = "/meeter-setup?canceled=true")

    return result
  } catch (error) {
    handleError(error)
  }
}

export async function checkCurrentMeeter() {
  try {
    return await get(`/meeters/getCurrentMeeter`)
  } catch (error) {
    return
  }
}

export async function getMeeterJobOffers(status) {
  try {
    return await get(`/meeters/getJobOffers/${status}`)
  } catch (error) {
    handleError(error)
  }
}

export async function getMeeterDasboardData() {
  try {
    const result = await get(`/meeters/getDashboardData`)

    if (!result?.isActive)
      return (window.location.href = "/meeter-setup?canceled=true")

    return result
  } catch (error) {
    handleError(error)
  }
}

export async function updateJobOfferStatus(jid, status) {
  try {
    return await post(`/meeters/updateJobOfferStatus`, {
      jid,
      status,
    })
  } catch (error) {
    handleError(error)
  }
}

export async function deleteMeeter() {
  try {
    return await del(`/meeters`)
  } catch (error) {
    handleError(error)
  }
}

export async function deactivateMeeter() {
  try {
    return await post(`meeters/deactivate`)
  } catch (error) {
    handleError(error)
  }
}

export async function getMeeterByID(id) {
  try {
    return await get(`/meeters/getMeeterByID/${id}`)
  } catch (error) {
    handleError(error)
  }
}
