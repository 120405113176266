import moment from "moment"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"

const Overview = ({ job, history }) => {
  const [error, setError] = useState(false)
  const [tomorrow, setTomorrow] = useState()
  const mapCompanySize = idx => {
    const sizes = process.env.REACT_APP_COMPANY_SIZES?.split(",")
    return sizes[idx - 1]
  }

  const handleEditClick = () => {
    var yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
    var editDate = new Date(job.lastEdited)

    if (job?.lastEdited && editDate > yesterday) {
      setTomorrow(new Date(editDate.getTime() + 24 * 60 * 60 * 1000))
      setError(true)
    } else history.push("/job-bearbeiten/" + job?.id)
  }

  return (
    <React.Fragment>
      <Col xl={3}>
        <Card>
          <CardBody>
            <h5 className="fw-semibold">Übersicht</h5>

            <div className="table-responsive">
              <table className="table">
                <tbody>
                  <tr>
                    <th scope="col">Firma</th>
                    <td scope="col">{job?.finder?.companyName}</td>
                  </tr>
                  <tr>
                    <th scope="row">Größe</th>
                    <td>
                      {mapCompanySize(job?.finder?.companySize)} Mitarbeiter
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">Branche</th>
                    <td scope="col">{job?.finder?.field}</td>
                  </tr>
                  <tr>
                    <th scope="col">Firmensitz</th>
                    <td scope="col">
                      {job?.finder?.postalCode + " " + job?.finder?.city}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Jobtyp</th>
                    <td>{job?.workingHours?.join(", ")}</td>
                  </tr>
                  <tr>
                    <th scope="row">Homeoffice:</th>
                    <td>{job?.homeOffice}</td>
                  </tr>
                  <tr>
                    <th scope="row">Bruttogehalt:</th>
                    <td>
                      {parseInt(job?.sallery)?.toLocaleString("de-DE")} € / Jahr
                    </td>
                  </tr>
                  {job?.salleryInfo && (
                    <tr>
                      <th scope="row">Zusatzinfo:</th>
                      <td>{job?.salleryInfo}</td>
                    </tr>
                  )}
                  <tr>
                    <th scope="row">Startdatum</th>
                    <td>{moment(job?.startDate).format("DD.MM.YYYY")}</td>
                  </tr>
                  {job?.finder?.contactPerson && (
                    <tr>
                      <th scope="row">Kontaktperson:</th>
                      <td>
                        {job?.finder?.gender + " " + job?.finder?.contactPerson}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="hstack gap-2">
              <button
                className="btn btn-soft-danger w-100"
                onClick={handleEditClick}
              >
                bearbeiten
              </button>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Modal
        isOpen={error}
        toggle={() => {
          setError(false)
        }}
        id="applyJobs"
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => setError(false)}
            id="applyJobsLabel"
            className="modal-header"
          >
            Überschreitung der Anzahl der Änderungen
          </ModalHeader>
          <ModalBody>
            <Row>
              <p>
                Damit wir sowohl für unsere Meeter, als auch für Sie als Finder,
                das beste Ergebnis liefern können, haben wir die Änderungen der
                Jobangebote auf maximal 1 mal pro Tag beschränkt. <br />
                <br /> Sie können daher erst ab{" "}
                <span className="text-primary">
                  {tomorrow?.toLocaleDateString() +
                    " " +
                    tomorrow?.toLocaleTimeString()}
                </span>{" "}
                das Jobangebot wieder ändern.
              </p>
              <Col lg={12}>
                <div className="text-end">
                  <button
                    className="btn btn-outline-secondary"
                    onClick={_ => setError(false)}
                  >
                    Abbrechen
                  </button>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default Overview
