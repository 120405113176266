import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { changeLoading } from "store/actions"

//import images
import avatar from "../../assets/images/logo-small.png"
import AboutMe from "./AboutMe"
import Sidebar from "./Sidebar"
import background from "../../assets/images/meeter/background-meeter.jpg"
import { getCurrentMeeter } from "helpers/meeter_backend_helper"

const MyProfile = () => {
  const dispatch = useDispatch()
  const [meeter, setMeeter] = useState({})
  const isMobile = screen.width < 1000

  useEffect(() => {
    async function setup() {
      dispatch(changeLoading(true))
      const result = await getCurrentMeeter()
      setMeeter(result)
      dispatch(changeLoading(false))
    }
    setup()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card
                className="mt-4"
                style={{
                  backgroundImage: isMobile ? "" : `url(${background})`,
                  backgroundSize: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundBlendMode: "darken",
                }}
              >
                <CardBody>
                  <div className="text-center mb-4 ">
                    <img
                      src={avatar}
                      alt=""
                      className="avatar-lg mx-auto mt-4"
                    />
                    <h5 className="mt-3 mb-1">
                      Meeter #{meeter?.mNumber ?? 1000}
                    </h5>
                    <p className="text-muted mb-3"></p>
                  </div>
                  {/* {!isMobile && (
                    <div className="d-flex align-items-center">
                      <ul className="list-unstyled hstack gap-3 mb-0 flex-grow-1"></ul>
                      <div className="hstack gap-2">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => window.print()}
                        >
                          Drucken{" "}
                          <i className="bx bx-download align-baseline ms-1"></i>
                        </button>
                      </div>
                    </div>
                  )} */}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Sidebar meeter={meeter} />
            <AboutMe meeter={meeter} />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MyProfile
