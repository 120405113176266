import React, { useEffect, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
} from "reactstrap"

import classnames from "classnames"
import { Link } from "react-router-dom"
import {
  initialMeeterValues,
  touchedMeeterValues,
} from "../../constants/SetupOptions"
import { useDispatch } from "react-redux"
import { changeLoading } from "store/actions"
import { getCurrentMeeter, updateMeeter } from "helpers/meeter_backend_helper"
import GeneralInfoPane from "./Panes/GeneralInfoPane"
import StrengthsPane from "./Panes/StrengthsPane"
import PersonalityPane from "./Panes/PersonalityPane"

const MeeterEdit = props => {
  const [meeter, setMeeter] = useState(initialMeeterValues)
  const [touched, setTouched] = useState(touchedMeeterValues)
  const [error, setError] = useState(false)
  const [activeTabVartical, setoggleTabVertical] = useState(1)
  const dispatch = useDispatch()
  const navItems = ["Generelle Infos", "Das liegt mir", "Das zeichnet mich aus"]

  useEffect(() => {
    async function setup() {
      dispatch(changeLoading(true))
      const user = await getCurrentMeeter()
      setMeeter(user)
      dispatch(changeLoading(false))
    }
    setup()
  }, [])

  function toggleTab() {
    if (allFieldsAreFilledOut())
      if (activeTabVartical === 3) handleSubmit()
      else toggleTabVertical(activeTabVartical + 1)
    else {
      setError(true)
      setTimeout(() => {
        setError(false)
      }, 3000)
    }
  }

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      if (tab >= 1 && tab <= 6) setoggleTabVertical(tab)
    }
    setTimeout(() => {
      scrollTo(0, 0)
    }, 50)
  }

  function allFieldsAreFilledOut() {
    if (activeTabVartical === 1) {
      setTouched({
        ...touched,
        firstname: true,
        lastname: true,
        phoneNr: true,
      })

      if (meeter.states.length == 0) return false
      if (meeter.workingHours.length == 0) return false
      if (!meeter.homeOffice) return false
      if (!meeter.gender) return false
      if (!meeter.phoneNr) return false
      if (!meeter.firstname) return false
      if (!meeter.lastname) return false
      if (!meeter.age) return false
    }
    if (activeTabVartical === 2) {
      if (!meeter.strengths || meeter.strengths.length < 3) return false
    }

    if (activeTabVartical === 3) {
      setTouched({
        ...touched,
        iLikeAboutMe: true,
        peopleILikeDo: true,
        myBestFriendSays: true,
        myIdolIs: true,
      })

      if (!meeter.iLikeAboutMe) return false
      if (!meeter.peopleILikeDo) return false
      if (!meeter.myBestFriendSays) return false
      if (!meeter.myIdolIs) return false
    }

    return true
  }

  async function handleSubmit() {
    dispatch(changeLoading(true))
    await updateMeeter(meeter)
    props.history.push("/dashboard")
  }

  return (
    <React.Fragment>
      <div
        className="page-content bg-primary bg-soft"
        style={{ minHeight: "850px" }}
      >
        <Container fluid={true}>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Profil bearbeiten</h4>
                  <div className="vertical-wizard wizard clearfix vertical">
                    <div className="steps clearfix">
                      <ul>
                        {navItems.map((item, idx) => (
                          <NavItem
                            key={idx + 1}
                            className={classnames({
                              current: activeTabVartical === idx + 1,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTabVartical === idx + 1,
                              })}
                              disabled
                            >
                              <span className="number">{idx + 1}.</span> {item}
                            </NavLink>
                          </NavItem>
                        ))}
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent
                        activeTab={activeTabVartical}
                        className="body"
                      >
                        <GeneralInfoPane
                          meeter={meeter}
                          setMeeter={setMeeter}
                          touched={touched}
                          setTouched={setTouched}
                        />

                        <StrengthsPane meeter={meeter} setMeeter={setMeeter} />

                        <PersonalityPane
                          meeter={meeter}
                          setMeeter={setMeeter}
                          touched={touched}
                          setTouched={setTouched}
                        />
                      </TabContent>
                    </div>

                    <div className="actions clearfix">
                      <ul>
                        {error && (
                          <li>
                            <p className="text-danger">
                              Es müssen alle Pflichtfelder befüllt sein
                            </p>
                          </li>
                        )}
                        <li
                          className={
                            activeTabVartical === 1
                              ? "previous disabled"
                              : "previous"
                          }
                        >
                          <Link
                            to="#"
                            className="btn"
                            onClick={() => {
                              toggleTabVertical(activeTabVartical - 1)
                            }}
                          >
                            Zurück
                          </Link>
                        </li>
                        <li>
                          <button
                            className="next btn btn-primary"
                            onClick={toggleTab}
                          >
                            {activeTabVartical === 3 ? "Speichern" : "Weiter"}
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MeeterEdit
