import React from "react"

import { Col, FormGroup, Input, Row, TabPane } from "reactstrap"

import {
  ageOptions,
  genderOptions,
  homeOfficeOptions,
  hoursOptions,
  stateOptions,
} from "../../../constants/SetupOptions"

const GeneralInfoPane = ({ meeter, setMeeter, touched, setTouched }) => {
  function handleStatesChange(selectedOption) {
    let states = ["Ganz Österreich"]
    if (selectedOption !== "Ganz Österreich") {
      states = meeter.states.filter(s => s !== "Ganz Österreich")

      if (states.includes(selectedOption))
        states = states.filter(s => s !== selectedOption)
      else states = [...states, selectedOption]
    }

    setMeeter({
      ...meeter,
      states: states,
    })
  }

  function handleHoursChange(selectedHour) {
    let workingHours = meeter.workingHours
    if (workingHours.includes(selectedHour))
      workingHours = workingHours.filter(h => h !== selectedHour)
    else workingHours = [...workingHours, selectedHour]

    setMeeter({
      ...meeter,
      workingHours: workingHours,
    })
  }

  return (
    <TabPane tabId={1}>
      <Row>
        <Col lg="6">
          <FormGroup className="mb-3">
            <div className="mb-3">
              <label className="control-label">Vorname</label>
              <Input
                type="text"
                className="form-control"
                placeholder="Vorname"
                value={meeter.firstname}
                maxLength={100}
                onChange={e =>
                  setMeeter({
                    ...meeter,
                    firstname: e.target.value,
                  })
                }
                onBlur={_ => setTouched({ ...touched, firstname: true })}
                invalid={!meeter?.firstname && touched.firstname}
              />
              <small
                className="form-text text-muted"
                style={{ fontStyle: "italic" }}
              >
                Dein Vor- und Nachname ist für Unternehmen erst nach deiner
                Zustimmung sichtbar.
              </small>
            </div>
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup className="mb-3">
            <div className="mb-3">
              <label className="control-label">Nachname</label>
              <Input
                type="text"
                className="form-control"
                placeholder="Nachname"
                value={meeter.lastname}
                maxLength={100}
                onChange={e =>
                  setMeeter({
                    ...meeter,
                    lastname: e.target.value,
                  })
                }
                onBlur={_ => setTouched({ ...touched, lastname: true })}
                invalid={!meeter?.lastname && touched.lastname}
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup className="mb-3">
            <div className="mb-3">
              <label className="control-label">Alter</label>

              <select
                className="form-select"
                value={meeter.age}
                onChange={e =>
                  setMeeter({
                    ...meeter,
                    age: e.target.value,
                  })
                }
              >
                <option disabled defaultValue value="">
                  Altersgruppe auswählen...
                </option>
                {ageOptions.map((value, idx) => (
                  <option key={idx} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup className="mb-3">
            <div className="mb-3">
              <label className="control-label">Telefonnummer</label>
              <Input
                type="phone"
                className="form-control"
                placeholder="Telefonnummer"
                value={meeter.phoneNr}
                onChange={e =>
                  setMeeter({
                    ...meeter,
                    phoneNr: e.target.value,
                  })
                }
                onBlur={_ => setTouched({ ...touched, phoneNr: true })}
                invalid={!meeter?.phoneNr && touched.phoneNr}
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <FormGroup>
          <div>
            <label className="control-label">
              Wo soll dein Arbeitsort sein?
              <br /> (Mehrfachantworten möglich)
            </label>
            <div>
              {stateOptions.map((state, index) => (
                <div key={index} className="d-inline">
                  <label className="m-1">
                    <input
                      type="checkbox"
                      className="card-radio-input"
                      readOnly
                      checked={meeter.states.includes(state)}
                    />
                    <div
                      className="card-radio p-2"
                      onClick={_ => handleStatesChange(state)}
                    >
                      <span>{state}</span>
                    </div>
                  </label>
                  {index === 4 && <br />}
                </div>
              ))}
            </div>
          </div>
        </FormGroup>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup className="mb-3">
            <div>
              <label className="control-label">
                Wie viele Stunden möchtest du in der Woche arbeiten?
                <br /> (Mehrfachantworten möglich)
              </label>
              <Row>
                <div>
                  {hoursOptions.map((hour, index) => (
                    <div key={index} className="d-inline">
                      <label className="m-1">
                        <Input
                          type="checkbox"
                          className="card-radio-input"
                          readOnly
                          checked={meeter.workingHours.includes(hour)}
                        />
                        <div
                          className="card-radio p-2"
                          onClick={_ => handleHoursChange(hour)}
                        >
                          <span>{hour}</span>
                        </div>
                      </label>
                    </div>
                  ))}
                </div>
              </Row>
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup className="mb-3">
            <div className="mb-3">
              <label className="control-label">
                Muss dein zukünftiger Job Home-Office beinhalten?
              </label>
              <Row>
                <div>
                  {homeOfficeOptions.map((homeOffice, index) => (
                    <div key={index} className="d-inline">
                      <label className="m-1">
                        <input
                          type="radio"
                          className="card-radio-input"
                          readOnly
                          checked={meeter.homeOffice == homeOffice}
                        />
                        <div
                          className="card-radio p-2"
                          onClick={_ =>
                            setMeeter({
                              ...meeter,
                              homeOffice: homeOffice,
                            })
                          }
                        >
                          <span>{homeOffice}</span>
                        </div>
                      </label>
                    </div>
                  ))}
                </div>
              </Row>
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup className="mb-3">
            <div className="mb-3">
              <label className="control-label">Geschlecht?</label>
              <Row>
                <div>
                  {genderOptions.map((gender, index) => (
                    <div key={index} className="d-inline">
                      <label className="m-1">
                        <input
                          type="radio"
                          className="card-radio-input"
                          readOnly
                          checked={meeter.gender.includes(gender)}
                        />
                        <div
                          className="card-radio p-2"
                          onClick={_ =>
                            setMeeter({
                              ...meeter,
                              gender: gender,
                            })
                          }
                        >
                          <span>{gender}</span>
                        </div>
                      </label>
                    </div>
                  ))}
                </div>
              </Row>
            </div>
          </FormGroup>
        </Col>
      </Row>
    </TabPane>
  )
}

export default GeneralInfoPane
