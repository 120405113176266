import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"

//Date Picker
import "react-datepicker/dist/react-datepicker.css"
import { useDispatch } from "react-redux"
import { changeLoading } from "store/actions"
import { getCurrentFinder, updateFinder } from "helpers/finder_backend_helper"

const JobFilter = ({ finder, history, filter, setFilter }) => {
  const base_URL = process.env.REACT_APP_BACKEND_URL
  const [licenseModal, setLicenseModal] = useState()
  const dispatch = useDispatch()
  const query = new URLSearchParams(window.location.search)
  const success = query.get("success")

  useEffect(() => {
    async function setup() {
      dispatch(changeLoading(true))
      const result = await getCurrentFinder()
      result.maxJobs++
      await updateFinder(result)
      history.push("/neuer-job")
    }
    if (success) setup()
  }, [success])

  const handleAddJobClick = () => {
    if (finder.jobs.filter(j => j.isActive).length < finder.maxJobs)
      history.push("/neuer-job")
    else setLicenseModal(true)
  }

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card className="job-filter">
            <CardBody>
              <Row className="g-3">
                <Col xxl={2} lg={4}>
                  <div className="position-relative">
                    <Input
                      type="text"
                      className="form-control"
                      id="searchJob"
                      placeholder="Suche"
                      value={filter}
                      onChange={e => setFilter(e.target.value)}
                    />
                  </div>
                </Col>

                <Col xxl={10}>
                  <div className="d-flex justify-content-end">
                    <button
                      onClick={handleAddJobClick}
                      className="btn btn-primary h-100 ms-auto"
                    >
                      <i className=" bx bx-add-to-queue align-middle"></i> Job
                      erstellen
                    </button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal
        isOpen={licenseModal}
        toggle={() => {
          setLicenseModal()
        }}
        id="applyJobs"
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => setLicenseModal()}
            id="applyJobsLabel"
            className="modal-header"
          >
            Jobangebot löschen
          </ModalHeader>
          <ModalBody>
            <Row>
              <h4>Überschreitung der Lizenzen!</h4>
              <p>
                Sie können leider nur maximal {finder?.maxJobs} Jobangebote
                ausschreiben. Falls Sie aber doch noch ein weiteres erstellen
                möchten, können Sie gerne jederzeit Ihre Lizenz erweitern.
              </p>
              <Col lg={12}>
                <div className="text-end">
                  <form
                    action={`${base_URL}/stripe/create-finder-jobs-checkout/${finder?.customerID}`}
                    method="POST"
                  >
                    <input
                      type={"hidden"}
                      name="email"
                      id="email"
                      value={finder?.email}
                    />
                    <button type="submit" className="btn btn-primary me-1">
                      Lizenz erweitern
                    </button>
                    <button
                      className="btn btn-outline-secondary"
                      onClick={e => {
                        e.preventDefault()
                        setLicenseModal()
                      }}
                    >
                      Abbrechen
                    </button>
                  </form>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default JobFilter
