import React, { useState } from "react"

import { Input, Modal, ModalHeader } from "reactstrap"
import defaultAvatar from "assets/images/logo-small.png"

const PromoCodeModal = () => {
  const [subscribemodal, setSubscribemodal] = useState(true)
  return (
    <Modal
      isOpen={subscribemodal}
      role="dialog"
      autoFocus={true}
      centered
      data-toggle="modal"
      toggle={() => {
        setSubscribemodal(!subscribemodal)
      }}
    >
      <div>
        <ModalHeader
          className="border-bottom-0"
          toggle={() => {
            setSubscribemodal(!subscribemodal)
          }}
        ></ModalHeader>
      </div>
      <div className="modal-body">
        <div className="text-center mb-4">
          <div className="avatar-md profile-user-wid mb-4 mx-auto">
            <img
              src={defaultAvatar}
              alt=""
              className="img-thumbnail rounded-circle"
            />
          </div>

          <div className="row justify-content-center">
            <div className="col-xl-10">
              <h4 className="text-primary">
                {" "}
                Meet2FIND 3 Monate kostenlos testen!
              </h4>
              <p className="text-muted font-size-14 mb-4">
                Jetzt diesen Vorteil sichern und unten angeführten Promocode auf
                der Bezahlseite einlösen.
              </p>

              <div className="input-group rounded bg-light">
                <Input
                  type="text"
                  readOnly
                  value="meet2find"
                  className="form-control bg-transparent border-0 text-center"
                />
              </div>
              <label
                className="form-check-label ms-2 text"
                htmlFor="trainee-checkbox"
              ></label>
              <small
                className="form-text text-muted mt-2"
                style={{ fontStyle: "italic" }}
              >
                Diese Gutscheincode ist nur für monatliche Bezahlungen gültig.
              </small>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default PromoCodeModal
