import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  const isMobile = screen.width < 1000

  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6} style={{ textAlign: isMobile ? "center" : "" }}>
              {new Date().getFullYear()} © meet2find - gefunden, ohne zu suchen
            </Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
                Designed & Developed by{" "}
                <a
                  href="https://just4online.com"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  JUST4ONLINE
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
