import React from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col } from "reactstrap"

const Sidebar = ({ meeter }) => {
  return (
    <React.Fragment>
      <Col lg={3}>
        <Card>
          <CardBody>
            <ul className="list-unstyled vstack gap-3 mb-0">
              <li>
                <div className="d-flex">
                  <i className="bx bxs-map-pin font-size-18 text-primary"></i>
                  <div className="ms-3">
                    <h6 className="mb-1 fw-semibold">
                      Gewünschter Arbeitsort:
                    </h6>
                    <span className="text-muted">
                      {meeter.states?.join(", ")}
                    </span>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex">
                  <i className="bx bx-calendar font-size-18 text-primary"></i>
                  <div className="ms-3">
                    <h6 className="mb-1 fw-semibold">Alter:</h6>
                    <span className="text-muted">{meeter.age} Jahre</span>
                  </div>
                </div>
              </li>
              {meeter.sallery && (
                <li>
                  <div className="d-flex">
                    <i className="bx bx-money font-size-18 text-primary"></i>
                    <div className="ms-3">
                      <h6 className="mb-1 fw-semibold">Gewünschtes Gehalt:</h6>
                      <span className="text-muted">{meeter.sallery} €</span>
                    </div>
                  </div>
                </li>
              )}

              {meeter.gender && (
                <li>
                  <div className="d-flex">
                    <i className="bx bx-user font-size-18 text-primary"></i>
                    <div className="ms-3">
                      <h6 className="mb-1 fw-semibold">Geschlecht:</h6>
                      {meeter.gender}
                    </div>
                  </div>
                </li>
              )}
              {meeter.workingHours && (
                <li>
                  <div className="d-flex">
                    <i className="bx bx-time-five font-size-18 text-primary"></i>
                    <div className="ms-3">
                      <h6 className="mb-1 fw-semibold">
                        Verfügbare Wochenstunden:
                      </h6>
                      <span className="text-muted">
                        {meeter.workingHours.join(", ")}
                      </span>
                    </div>
                  </div>
                </li>
              )}
              <li>
                <div className="d-flex">
                  <i className="bx bx-home-circle font-size-18 text-primary"></i>
                  <div className="ms-3">
                    <h6 className="mb-1 fw-semibold">Home-Office:</h6>
                    <span className="text-muted">{meeter.homeOffice}</span>
                  </div>
                </div>
              </li>
              <li className="hstack gap-2 mt-3">
                <Link
                  to="/meeter-bearbeiten"
                  className="btn btn-soft-danger w-100"
                >
                  Bearbeiten
                </Link>
              </li>
            </ul>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default Sidebar
