import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { changeLoading } from "store/actions"

//import images
import avatar from "../../assets/images/meeter/default-avatar.png"
import AboutMe from "./AboutMe"
import Sidebar from "./Sidebar"
import background from "../../assets/images/meeter/background-meeter.jpg"
import { getJobById } from "helpers/finder_backend_helper"
import { getMeeterByID } from "helpers/meeter_backend_helper"

const MeeterProfile = props => {
  const dispatch = useDispatch()
  const [meeter, setMeeter] = useState({})
  const { mid, jid } = useParams()
  const query = new URLSearchParams(window.location.search)
  const confirmed = query.get("confirmed")
  const isMobile = screen.width < 1000

  useEffect(() => {
    async function getMeeter() {
      dispatch(changeLoading(true))
      const result = await getMeeterByID(mid)
      if (!result) props.history.push("/404")

      await checkIfConfirmed(result)

      dispatch(changeLoading(false))
    }
    getMeeter()
  }, [])

  const checkIfConfirmed = async result => {
    if (confirmed) {
      const job = await getJobById(jid)
      const jobMeeter = job.meeters.find(m => m.id === mid)

      if (jobMeeter.status === "accepted") meeter.confirmed = true
      setMeeter({ ...result, confirmed: true })
    } else setMeeter(result)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card
                className="mt-4"
                style={{
                  backgroundImage: isMobile ? "" : `url(${background})`,
                  backgroundSize: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundBlendMode: "darken",
                }}
              >
                <CardBody>
                  <div className="text-center mb-4 ">
                    <img
                      src={avatar}
                      alt=""
                      className="avatar-lg mx-auto mt-4"
                    />
                    <h5 className="mt-3 mb-1">
                      {meeter?.confirmed
                        ? meeter.firstname + " " + meeter.lastname
                        : "Meeter #" + meeter?.mNumber ?? 1000}
                    </h5>
                    <p className="text-muted mb-3"></p>
                  </div>
                  {/* {!isMobile && (
                    <div className="d-flex align-items-center">
                      <ul className="list-unstyled hstack gap-3 mb-0 flex-grow-1"></ul>
                      <div className="hstack gap-2">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => window.print()}
                        >
                          Drucken{" "}
                          <i className="bx bx-download align-baseline ms-1"></i>
                        </button>
                      </div>
                    </div>
                  )} */}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Sidebar meeter={meeter} history={props.history} />
            <AboutMe meeter={meeter} />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MeeterProfile
