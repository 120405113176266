import { del, get, handleError, post, put, upload } from "./api_helper"

export async function createFinder(finder) {
  try {
    await post(`finders/create`, finder)
  } catch (error) {
    handleError(error)
  }
}

export async function uploadLogo(file) {
  try {
    return await upload(`finders/uploadLogo`, file)
  } catch (error) {
    handleError(error)
  }
}

export async function updateFinder(finder) {
  try {
    await put(`finders/update`, finder)
  } catch (error) {
    handleError(error)
  }
}

export async function getCurrentFinder() {
  try {
    const result = await get(`finders/getCurrentFinder`)

    if (!result?.isActive)
      return (window.location.href = "/finder-setup?canceled=true")
    return result
  } catch (error) {
    handleError(error)
  }
}

export async function checkCurrentFinder() {
  try {
    return await get(`finders/getCurrentFinder`)
  } catch (error) {
    return
  }
}

export async function getFinderJobs() {
  try {
    const result = await get(`finders/getJobs`)

    if (!result?.isActive) {
      window.location.href = "/finder-setup?canceled=true"
      return
    }
    return result
  } catch (error) {
    handleError(error)
  }
}

export async function addFinderJob(job) {
  try {
    await post(`finders/addJob`, job)
  } catch (error) {
    handleError(error)
  }
}

export async function updateFinderJob(job) {
  try {
    return await put(`finders/updateJob`, job)
  } catch (error) {
    handleError(error)
  }
}

export async function deleteFinder() {
  try {
    return await del(`finders`)
  } catch (error) {
    handleError(error)
  }
}

export async function deactivateFinder() {
  try {
    return await post(`finders/deactivate`)
  } catch (error) {
    handleError(error)
  }
}

export async function getJobById(id) {
  try {
    return await get(`finders/getJobById/${id}`)
  } catch (error) {
    handleError(error)
  }
}

export async function getJobMeeters(id) {
  try {
    return await get(`finders/getJobMeeters/${id}`)
  } catch (error) {
    handleError(error)
  }
}

export async function addMeeterRequest(jid, mid, status) {
  try {
    return await post(`finders/addMeeterRequest`, { jid, mid, status })
  } catch (error) {
    handleError(error)
  }
}
