import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { useDispatch } from "react-redux"
import { withRouter } from "react-router-dom"
import Breadcrumb from "../../components/Common/Breadcrumb"
import avatar from "../../assets/images/logo-small.png"
import { changeLoading } from "../../store/actions"
import {
  deactivateFinder,
  deleteFinder,
  getCurrentFinder,
  getFinderJobs,
} from "helpers/finder_backend_helper"
import {
  deactivateMeeter,
  deleteMeeter,
  getCurrentMeeter,
} from "helpers/meeter_backend_helper"

import { changePassword, updateEmail } from "helpers/auth_backend_helper"
import { cancelFinderJobSubscription } from "helpers/stripe_backend_helper"

const Settings = props => {
  const base_URL = process.env.REACT_APP_BACKEND_URL
  const userType = localStorage.getItem("userType")
  const email = localStorage.getItem("email")
  const dispatch = useDispatch()
  const [user, setUser] = useState()
  const [password, setPassword] = useState("")
  const [deleteModal, setDeleteModal] = useState(false)
  const [deactivationModal, setDeactivationModal] = useState(false)
  const [cancelJobSubscription, setCancelJobSubscription] = useState(false)
  const [newEmail, setNewEmail] = useState("")
  const [success, setSuccess] = useState()
  const [error, setError] = useState()
  const isFinder = userType === "finder"

  const displayMessage = (message, setMessage) => {
    setMessage(message)
    setTimeout(() => {
      setMessage()
    }, 3000)
  }

  useEffect(() => {
    async function setup() {
      dispatch(changeLoading(true))

      let result
      if (userType === "meeter") result = await getCurrentMeeter()
      else result = await getCurrentFinder()
      setUser({ ...result, email })
      dispatch(changeLoading(false))
    }

    setup()
  }, [dispatch])

  const handlePasswordChange = async e => {
    try {
      dispatch(changeLoading(true))
      e.preventDefault()
      if (password.length < 6) {
        dispatch(changeLoading(false))
        return displayMessage(
          "Das Passwort muss mindestens 6 Zeichen lang sein!",
          setError
        )
      }
      await changePassword(password)
      displayMessage("Passwort erfolgreich geändert!", setSuccess)
      setPassword("")
      dispatch(changeLoading(false))
    } catch (error) {
      displayMessage(error, setError)
      dispatch(changeLoading(false))
    }
  }

  const handleEmailChange = async e => {
    try {
      dispatch(changeLoading(true))
      e.preventDefault()
      await updateEmail(newEmail, user.customerID)
      props.history.push("/logout")
      dispatch(changeLoading(false))
    } catch (error) {
      console.log(error)
      displayMessage(error, setError)
      dispatch(changeLoading(false))
    }
  }

  const handleAccountDeactivation = async () => {
    dispatch(changeLoading(true))
    if (isFinder) await deactivateFinder()
    else await deactivateMeeter()
    window.location.href = "https://meet2find.at"
  }

  const handleAccountDeletion = async () => {
    dispatch(changeLoading(true))
    if (isFinder) await deleteFinder()
    else await deleteMeeter()
    window.location.href = "https://meet2find.at"
  }

  const openDeleteJob = async () => {
    dispatch(changeLoading(true))
    const result = await getFinderJobs()
    if (result?.jobs?.filter(j => j.isActive)?.length === user.maxJobs)
      setError(
        "Sie haben leider noch alle Jobausschreibungen aktiv. Bitte deaktivieren Sie eine Jobausschreibung, um eine Lizenz zu löschen."
      )
    else setCancelJobSubscription(true)
    dispatch(changeLoading(false))
  }

  const handleJobSubscriptionCancel = async () => {
    dispatch(changeLoading(true))
    if (isFinder) await cancelFinderJobSubscription(user.email)
    setCancelJobSubscription(false)
    setSuccess("Die Joblizenz wurde erfolgreich entfernt")
    setUser({ ...user, maxJobs: user.maxJobs - 1 })
    dispatch(changeLoading(false))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="meet2find" breadcrumbItem="Profil" />
          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={user?.logoURL ?? avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail mr-2"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted ms-3">
                        <h5>
                          {isFinder
                            ? user?.companyName
                            : user?.firstname + " " + user?.lastname}
                        </h5>
                        <p className="mb-1">{user?.email}</p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                      <Button
                        color="danger"
                        onClick={_ => setDeleteModal(true)}
                      >
                        Profil löschen
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">E-Mail Adresse ändern</h4>

                  <Form
                    className="form-horizontal"
                    onSubmit={handleEmailChange}
                  >
                    <div className="form-group">
                      <Label className="form-label">E-Mail</Label>
                      <Input
                        name="email"
                        value={newEmail}
                        onChange={e => setNewEmail(e.target.value)}
                        className="form-control"
                        placeholder="Neue E-Mail"
                        type="email"
                      />
                    </div>
                    <div className=" mt-4">
                      <Button type="submit" color="primary">
                        E-Mail aktualisieren
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Passwort ändern</h4>
                  <Form
                    className="form-horizontal"
                    onSubmit={handlePasswordChange}
                  >
                    <div className="form-group">
                      <Label className="form-label">Passwort</Label>
                      <Input
                        name="passwort"
                        className="form-control"
                        placeholder="min. 6 Zeichen"
                        type="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                      />
                    </div>
                    <div className=" mt-4">
                      <Button type="submit" color="primary">
                        Passwort aktualisieren
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            {isFinder && (
              <>
                <Col md={4} className="pb-4">
                  <Card className="h-100 mb-1">
                    <CardBody className="d-flex flex-column justify-content-between">
                      <div>
                        <h4 className="card-title mb-4">
                          Zahlungsmöglichkeit aktualisieren
                        </h4>
                        <p>
                          Hier ganz einfach die Zahlungsdaten aktualisieren.
                          Falls Sie ein monatliches Abo inklusive Gutscheincode
                          gewählt haben, sind die ersten 3 Monate kostenlos.
                        </p>
                      </div>
                      <div>
                        <form
                          action={
                            base_URL +
                            "/stripe/create-customer-portal/" +
                            user?.customerID
                          }
                        >
                          <Button type="submit" color="primary">
                            Zahlungen aktualisieren
                          </Button>
                        </form>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                {user?.maxJobs > 1 && (
                  <Col md={4} className="pb-4">
                    <Card>
                      <CardBody>
                        <h4 className="card-title mb-2">Joblizenz entfernen</h4>
                        <p>
                          Sie können derzeit {user.maxJobs} Jobausschreibungen
                          anlegen. Falls Sie eine davon nicht mehr benötigen,
                          können Sie diese gerne kündigen.
                        </p>
                        <div className="mt-4">
                          <Button color="primary" onClick={openDeleteJob}>
                            Joblizenz entfernen
                          </Button>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                )}
                <Col md={4} className="pb-4">
                  <Card>
                    <CardBody>
                      <h4 className="card-title mb-4">Abo kündigen</h4>
                      <p>Hier können Sie Ihr monatliches Abo kündigen.</p>
                      <div className="mt-4">
                        <Button
                          color="primary"
                          onClick={_ => setDeactivationModal(true)}
                        >
                          Abo kündigen
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </>
            )}
          </Row>
        </Container>
      </div>

      <Modal
        isOpen={error != "" && error != undefined}
        centered
        toggle={() => {
          setError()
        }}
      >
        <div className="modal-content">
          <ModalHeader toggle={() => setError()} className="modal-header">
            Fehler!
          </ModalHeader>
          <ModalBody>
            <Row>
              <p>{error}</p>
              <Col lg={12}>
                <div className="text-end">
                  <button
                    className="btn btn-outline-secondary"
                    onClick={_ => setError()}
                  >
                    Okay
                  </button>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>
      <Modal
        isOpen={cancelJobSubscription}
        centered
        toggle={() => {
          setCancelJobSubscription()
        }}
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => setCancelJobSubscription()}
            className="modal-header"
          >
            Jobausschreibung entfernen
          </ModalHeader>
          <ModalBody>
            <Row>
              <p>
                Sind sie sicher, dass Sie eine Jobausschreibung entfernen
                wollen?
              </p>
              <Col lg={12}>
                <div className="text-end">
                  <button
                    className="btn btn-danger me-1"
                    onClick={_ => handleJobSubscriptionCancel()}
                  >
                    Ja, Jobausschreibung entfernen
                  </button>
                  <button
                    className="btn btn-outline-secondary"
                    onClick={_ => setCancelJobSubscription(false)}
                  >
                    Abbrechen
                  </button>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>
      <Modal
        isOpen={deactivationModal}
        centered
        toggle={() => {
          setDeactivationModal()
        }}
        id="applyJobs"
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => setDeactivationModal()}
            id="applyJobsLabel"
            className="modal-header"
          >
            Abo kündigen
          </ModalHeader>
          <ModalBody>
            <Row>
              <p>
                Sind Sie sicher dass Sie Ihr Abo kündigen wollen? Ihr Daten
                bleiben erhalten und Sie können jederzeit ihr Profil erneut
                aktivieren.
              </p>
              <Col lg={12}>
                <div className="text-end">
                  <button
                    className="btn btn-danger me-1"
                    onClick={_ => handleAccountDeactivation()}
                  >
                    Ja, Abo deaktivieren
                  </button>
                  <button
                    className="btn btn-outline-secondary"
                    onClick={_ => setDeactivationModal(false)}
                  >
                    Abbrechen
                  </button>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>
      <Modal
        isOpen={deleteModal}
        centered
        toggle={() => {
          setDeleteModal()
        }}
        id="applyJobs"
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => setDeleteModal()}
            id="applyJobsLabel"
            className="modal-header"
          >
            Account löschen
          </ModalHeader>
          <ModalBody>
            <Row>
              <p>
                Sind Sie sicher dass Sie Ihren Account löschen wollen?
                <br />
                <span className="text-danger">
                  Dieser Schritt kann nicht rückgängig gemacht werden
                </span>
              </p>
              <Col lg={12}>
                <div className="text-end">
                  <button
                    className="btn btn-danger me-1"
                    onClick={_ => handleAccountDeletion()}
                  >
                    Ja, Account löschen
                  </button>
                  <button
                    className="btn btn-outline-secondary"
                    onClick={_ => setDeleteModal(false)}
                  >
                    Abbrechen
                  </button>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(Settings)
