import React, { useState } from "react"

import { Col, Input, Modal, ModalHeader } from "reactstrap"
import defaultAvatar from "assets/images/logo-small.png"

const InvoicePromoCodeInfo = ({ modal, setModal, callback }) => {
  return (
    <Modal
      isOpen={modal}
      role="dialog"
      autoFocus={true}
      centered
      data-toggle="modal"
      toggle={() => {
        setModal(!modal)
      }}
    >
      <div>
        <ModalHeader
          className="border-bottom-0"
          toggle={() => {
            setModal(!modal)
          }}
        ></ModalHeader>
      </div>
      <div className="modal-body">
        <div className="text-center mb-4">
          <div className="avatar-md profile-user-wid mb-4 mx-auto">
            <img
              src={defaultAvatar}
              alt=""
              className="img-thumbnail rounded-circle"
            />
          </div>

          <div className="row justify-content-center">
            <div className="col-xl-10">
              <h4 className="text-primary"> Kauf auf Rechnung</h4>
              <p className="text-muted font-size-14 mb-4">
                Mit dem Kauf auf Rechnung erhalten Sie eine monatliche Rechnung.
                Da die ersten 3 Monate gratis sind, erhalten Sie erst ab dem
                4.Monat eine Rechnung von uns. Wir melden uns aber noch vor
                Ablauf der 3 Monate bei Ihnen.
              </p>
            </div>

            <Col lg={12}>
              <div className="text-end">
                <button
                  className="btn btn-danger me-1"
                  onClick={_ => setModal()}
                >
                  abbrechen
                </button>
                <button className="btn btn-success me-1" onClick={callback}>
                  jetzt zahlungspflichtig abonnieren
                </button>
              </div>
            </Col>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default InvoicePromoCodeInfo
