import React, { useState } from "react"

import { Col, FormGroup, Input, Label, Row, TabPane } from "reactstrap"
import { companySizeOptions } from "constants/SetupOptions"
import { uploadLogo } from "helpers/finder_backend_helper"
import { changeLoading } from "store/actions"
import { useDispatch } from "react-redux"

const GeneralInfosPane = ({ finder, setFinder, touched, setTouched }) => {
  const dispatch = useDispatch()

  const handleLogoChange = async e => {
    dispatch(changeLoading(false))
    const formData = new FormData()
    formData.append("logo", e.target.files[0])
    const result = await uploadLogo(formData)
    setFinder({ ...finder, logoURL: result })
    dispatch(changeLoading(false))
  }

  return (
    <TabPane tabId={1}>
      <Row>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label htmlFor="basicpill-name-input12">Firmenname</Label>
            <Input
              type="text"
              className="form-control"
              placeholder="Name der Firma"
              value={finder.companyName}
              onChange={e =>
                setFinder({
                  ...finder,
                  companyName: e.target.value,
                })
              }
              onBlur={_ => setTouched({ ...touched, companyName: true })}
              invalid={!finder?.companyName && touched.companyName}
            />
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label htmlFor="basicpill-size-input22">Mitarbeiteranzahl</Label>
            <select
              className="form-control"
              value={finder.companySize}
              onChange={e =>
                setFinder({
                  ...finder,
                  companySize: e.target.value,
                })
              }
              options={companySizeOptions}
            >
              <option value={1}>1-9</option>
              <option value={2}>10-49</option>
              <option value={3}>50-249</option>
              <option value={4}>&gt;249</option>
            </select>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup className="mb-3 position-relative">
            <Label htmlFor="basicpill-postalcode-input42">UID-Nummer</Label>
            <form className="needs-validation">
              <Input
                type="text"
                className="form-control"
                placeholder="ATU11223344"
                value={finder.vatNumber}
                onChange={e =>
                  setFinder({
                    ...finder,
                    vatNumber: e.target.value,
                  })
                }
                onBlur={_ => setTouched({ ...touched, vatNumber: true })}
                invalid={!finder?.vatNumber && touched.vatNumber}
              />
            </form>
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label htmlFor="basicpill-place-input12">Firmenbuchnummer</Label>
            <form className="needs-validation">
              <Input
                type="text"
                className="form-control"
                placeholder="12345n"
                value={finder.companyNr}
                onChange={e =>
                  setFinder({
                    ...finder,
                    companyNr: e.target.value,
                  })
                }
                onBlur={_ => setTouched({ ...touched, companyNr: true })}
                invalid={!finder?.companyNr && touched.companyNr}
              />
            </form>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup className="mb-3">
            <div className="mb-3">
              <label className="control-label">Firmensitz</label>
              <Input
                type="text"
                className="form-control"
                placeholder="Straße + Hausnummer"
                value={finder.street}
                onChange={e =>
                  setFinder({
                    ...finder,
                    street: e.target.value,
                  })
                }
                onBlur={_ => setTouched({ ...touched, street: true })}
                invalid={!finder?.street && touched.street}
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <FormGroup className="mb-3">
            <div className="mb-3">
              <label className="control-label">Postleitzahl</label>
              <Input
                type="text"
                className="form-control"
                placeholder="Postleitzahl"
                value={finder.postalCode}
                onChange={e =>
                  setFinder({
                    ...finder,
                    postalCode: e.target.value,
                  })
                }
                onBlur={_ => setTouched({ ...touched, postalCode: true })}
                invalid={!finder?.postalCode && touched.postalCode}
              />
            </div>
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup className="mb-3">
            <div className="mb-3">
              <label className="control-label">Stadt / Ort</label>
              <Input
                type="text"
                className="form-control"
                placeholder="Stadt oder Ort"
                value={finder.city}
                onChange={e =>
                  setFinder({
                    ...finder,
                    city: e.target.value,
                  })
                }
                onBlur={_ => setTouched({ ...touched, city: true })}
                invalid={!finder?.city && touched.city}
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label htmlFor="basicpill-postalcode-input42">Branche</Label>
            <Input
              type="text"
              className="form-control"
              placeholder="Branche"
              value={finder.field}
              onChange={e =>
                setFinder({
                  ...finder,
                  field: e.target.value,
                })
              }
              onBlur={_ => setTouched({ ...touched, field: true })}
              invalid={!finder?.field && touched.field}
            />
          </FormGroup>
        </Col>
        <Col lg="6">
          <Label htmlFor="basicpill-place-input12">Logo (optional)</Label>
          <Input
            className="form-control"
            accept="image/png, image/jpeg"
            onChange={handleLogoChange}
            type="file"
            id="formFile"
          />
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label htmlFor="basicpill-postalcode-input42">
              Ansprechpartner:in
            </Label>
            <Input
              type="text"
              className="form-control"
              placeholder="Name der Person"
              value={finder.contactPerson}
              onChange={e =>
                setFinder({
                  ...finder,
                  contactPerson: e.target.value,
                })
              }
              onBlur={_ => setTouched({ ...touched, contactPerson: true })}
              invalid={!finder?.contactPerson && touched.contactPerson}
            />
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup className="mb-3">
            <Label htmlFor="basicpill-size-input22">Geschlecht</Label>
            <select
              className="form-select"
              value={finder.gender ?? "Geschlecht"}
              onChange={e =>
                setFinder({
                  ...finder,
                  gender: e.target.value,
                })
              }
            >
              <option value="Geschlecht" disabled hidden>
                Geschlecht wählen
              </option>
              <option value="Herr">Herr</option>
              <option value="Frau">Frau</option>
              <option value="keine Angabe">keine Angabe</option>
            </select>
          </FormGroup>
        </Col>
      </Row>
    </TabPane>
  )
}

export default GeneralInfosPane
