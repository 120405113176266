import React from "react"
import { Card, CardBody, Col } from "reactstrap"

const AboutMe = ({ meeter }) => {
  return (
    <React.Fragment>
      <Col lg={9}>
        <Card>
          <CardBody>
            <h5 className="mb-3">Meine Eigenschaften:</h5>
            <ul className="vstack gap-3 mb-5">
              {meeter.strengths?.map((strenth, idx) => (
                <li key={idx}>{strenth}</li>
              ))}
            </ul>

            <h5 className="mb-3">Mehr über mich:</h5>
            <ul className="verti-timeline list-unstyled">
              <li className="event-list">
                <div className="event-timeline-dot">
                  <i className="bx bx-right-arrow-circle"></i>
                </div>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <div>
                      <h6 className="font-size-14 mb-1">Ich mag an mir ...</h6>

                      <p className="text-muted mb-0">{meeter.iLikeAboutMe}</p>
                    </div>
                  </div>
                </div>
              </li>
              <li className="event-list">
                <div className="event-timeline-dot">
                  <i className="bx bx-right-arrow-circle"></i>
                </div>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <div>
                      <h6 className="font-size-14 mb-1">
                        Menschen, die ich sehr schätze, verhalten sich mir
                        gegenüber:
                      </h6>

                      <p className="text-muted mb-0">{meeter.peopleILikeDo}</p>
                    </div>
                  </div>
                </div>
              </li>
              <li className="event-list">
                <div className="event-timeline-dot">
                  <i className="bx bx-right-arrow-circle"></i>
                </div>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <div>
                      <h6 className="font-size-14 mb-1">
                        Meine beste Freundin bzw. mein bester Freund raten mir
                        beruflich folgendes zu tun:
                      </h6>

                      <p className="text-muted mb-0">
                        {meeter.myBestFriendSays}
                      </p>
                    </div>
                  </div>
                </div>
              </li>
              <li className="event-list">
                <div className="event-timeline-dot">
                  <i className="bx bx-right-arrow-circle"></i>
                </div>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <div>
                      <h6 className="font-size-14 mb-1">
                        Das ist mein größtes Vorbild und diese Eigenschaften
                        beeindrucken mich besonders:
                      </h6>

                      <p className="text-muted mb-0">{meeter.myIdolIs}</p>
                    </div>
                  </div>
                </div>
              </li>
              {meeter.whatIWantToSay && (
                <li className="event-list">
                  <div className="event-timeline-dot">
                    <i className="bx bx-right-arrow-circle"></i>
                  </div>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <div>
                        <h6 className="font-size-14 mb-1">
                          Was ich noch sagen möchte ist:
                        </h6>

                        <p className="text-muted mb-0">
                          {meeter.whatIWantToSay}
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              )}
            </ul>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default AboutMe
