import { getJobMeeters } from "helpers/finder_backend_helper"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Link, useParams } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Row,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { changeLoading } from "store/actions"
import defaultAvatar from "../../assets/images/logo-small.png"
import { sendContactReminderRequest } from "helpers/mail_helper"

const List = () => {
  const [activeTab, toggleTab] = useState("1")
  const [selectedMeeter, setSelectedMeeter] = useState()
  const [success, setSuccess] = useState()
  const [job, setJob] = useState({})
  const dispatch = useDispatch()
  const { id } = useParams()
  const newMeeters = job?.meeters?.filter(m => m.status === "new")
  const acceptedMeeters = job?.meeters?.filter(m => m.status === "accepted")
  const openMeeters = job?.meeters?.filter(m => m.status === "open")
  const declinedMeeters = job?.meeters?.filter(m => m.status === "declined")
  const deletedMeeters = job?.meeters?.filter(m => m.status === "deleted")
  const countMatrix = [
    newMeeters?.length ?? 0,
    acceptedMeeters?.length ?? 0,
    openMeeters?.length ?? 0,
    declinedMeeters?.length ?? 0,
    deletedMeeters?.length ?? 0,
  ]

  useEffect(() => {
    async function getCurrentFinder() {
      dispatch(changeLoading(true))
      const result = await getJobMeeters(id)

      setJob(result)
      dispatch(changeLoading(false))
    }
    getCurrentFinder()
  }, [])

  const navitems = [
    "Neue Meeter",
    "Bestätigte Meeter",
    "Offene Meeter",
    "Abgelehnte Anfragen",
    "Abgelehnte Meeter",
  ]

  const sendMeeterReminder = async () => {
    dispatch(changeLoading(true))

    await sendContactReminderRequest(selectedMeeter.email)

    setSuccess(true)
    dispatch(changeLoading(false))
  }

  return (
    <React.Fragment>
      <ul
        className="nav nav-tabs nav-tabs-custom justify-content-center pt-2"
        role="tablist"
      >
        {navitems.map((item, key) => (
          <NavItem key={key}>
            <NavLink
              to="#"
              className={activeTab === "" + (key + 1) ? "active" : ""}
              onClick={() => {
                toggleTab("" + (key + 1))
              }}
            >
              {item}{" "}
              <span className="badge rounded-pill bg-primary ms-2 p-1 float-end">
                {countMatrix[key]}
              </span>
            </NavLink>
          </NavItem>
        ))}
      </ul>
      <TabContent className="p-4" activeTab={activeTab}>
        <TabPane tabId="1">
          <div>
            <Row>
              {(!newMeeters || newMeeters?.length == 0) && (
                <p className="text-center mt-4">
                  Sobald die Datenbank mit ausreichend Meeter gefüllt ist,
                  bekommen Sie Ihre perfekten Matches übermittelt.
                </p>
              )}
              {newMeeters?.map((meeter, key) => (
                <Col xl={3} key={key}>
                  <Card>
                    <CardBody>
                      <div className="text-center mb-3">
                        <img
                          src={defaultAvatar}
                          alt=""
                          className="avatar-sm rounded-circle"
                        />
                        <h6 className="font-size-15 mt-3 mb-1">
                          Meeter #{meeter.mNumber}
                        </h6>
                        <p className="mb-0 text-muted">{meeter.age} Jahre</p>
                      </div>
                      <div className="d-flex mb-3 justify-content-center gap-2 text-muted">
                        <div>
                          <i className="bx bx-map align-middle text-primary"></i>{" "}
                          {meeter.states.join(", ")}
                        </div>
                        {meeter.sallary && (
                          <p className="mb-0 text-center">
                            <i className="bx bx-money align-middle text-primary"></i>{" "}
                            {meeter.sallary} €
                          </p>
                        )}
                      </div>

                      <div className="mt-4 pt-1">
                        <Link
                          to={"/finder-jobs/" + id + "/meeters/" + meeter.id}
                          className="btn btn-soft-primary d-block"
                        >
                          Mehr Informationen
                        </Link>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </TabPane>

        <TabPane tabId="2">
          <div>
            <Row>
              {(!acceptedMeeters || acceptedMeeters?.length == 0) && (
                <p className="text-center mt-4">
                  Sie haben derzeit noch keine bestätigten Meeter. Sobald ein
                  Meeter oder eine Meeterin die Kontakdaten freigibt, sind sie
                  hier zu sehen.
                </p>
              )}
              {acceptedMeeters?.map((meeter, key) => (
                <Col xl={3} key={key}>
                  <Card>
                    <CardBody>
                      <div className="text-center mb-3">
                        <img
                          src={defaultAvatar}
                          alt=""
                          className="avatar-sm rounded-circle"
                        />
                        <h6 className="font-size-15 mt-3 mb-1">
                          {meeter.firstname} {meeter.lastname}
                        </h6>
                        <p className="mb-0 text-muted">{meeter.age} Jahre</p>
                      </div>
                      <div className="d-flex mb-3 justify-content-center gap-2 text-muted">
                        <div>
                          <i className="bx bx-map align-middle text-primary"></i>{" "}
                          {meeter.states.join(", ")}
                        </div>
                        {meeter.sallary && (
                          <p className="mb-0 text-center">
                            <i className="bx bx-money align-middle text-primary"></i>{" "}
                            {meeter.sallary} €
                          </p>
                        )}
                      </div>

                      <div className="mt-4 pt-1">
                        <Link
                          to={
                            "/finder-jobs/" +
                            id +
                            "/meeters/" +
                            meeter.id +
                            "?confirmed=true"
                          }
                          className="btn btn-soft-primary d-block"
                        >
                          Mehr Informationen
                        </Link>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </TabPane>

        <TabPane tabId="3">
          <div>
            <Row>
              {(!openMeeters || openMeeters?.length == 0) && (
                <p className="text-center mt-4">
                  Es sind derzeit keine offenen Kontaktanfragen.
                </p>
              )}
              {openMeeters?.map((meeter, key) => (
                <Col xl={3} key={key}>
                  <Card>
                    <CardBody>
                      <div className="text-center mb-3">
                        <img
                          src={defaultAvatar}
                          alt=""
                          className="avatar-sm rounded-circle"
                        />
                        <h6 className="font-size-15 mt-3 mb-1">
                          Meeter #{meeter.mNumber}
                        </h6>
                        <p className="mb-0 text-muted">{meeter.age} Jahre</p>
                      </div>
                      <div className="d-flex mb-3 justify-content-center gap-2 text-muted">
                        <div>
                          <i className="bx bx-map align-middle text-primary"></i>{" "}
                          {meeter.states.join(", ")}
                        </div>
                        {meeter.sallary && (
                          <p className="mb-0 text-center">
                            <i className="bx bx-money align-middle text-primary"></i>{" "}
                            {meeter.sallary} €
                          </p>
                        )}
                      </div>
                      <div className="mt-4 pt-1">
                        <button
                          className="btn btn-soft-primary d-block w-100"
                          onClick={_ => setSelectedMeeter(meeter)}
                        >
                          Reminder senden
                        </button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </TabPane>
        <TabPane tabId="4">
          <div>
            <Row>
              {(!declinedMeeters || declinedMeeters?.length == 0) && (
                <p className="text-center mt-4">
                  Sie haben noch keine abgelehnten Kontaktanfragen
                </p>
              )}
              {declinedMeeters?.map((meeter, key) => (
                <Col xl={3} key={key}>
                  <Card>
                    <CardBody>
                      <div className="text-center mb-3">
                        <img
                          src={defaultAvatar}
                          alt=""
                          className="avatar-sm rounded-circle"
                        />
                        <h6 className="font-size-15 mt-3 mb-1">
                          Meeter #{meeter.mNumber}
                        </h6>
                        <p className="mb-0 text-muted">{meeter.age} Jahre</p>
                      </div>
                      <div className="d-flex mb-3 justify-content-center gap-2 text-muted">
                        <div>
                          <i className="bx bx-map align-middle text-primary"></i>{" "}
                          {meeter.states.join(", ")}
                        </div>
                        {meeter.sallary && (
                          <p className="mb-0 text-center">
                            <i className="bx bx-money align-middle text-primary"></i>{" "}
                            {meeter.sallary} €
                          </p>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </TabPane>
        <TabPane tabId="5">
          <div>
            <Row>
              {(!deletedMeeters || deletedMeeters?.length == 0) && (
                <p className="text-center mt-4">
                  Sie haben noch keine Meeter abgelehnt
                </p>
              )}
              {deletedMeeters?.map((meeter, key) => (
                <Col xl={3} key={key}>
                  <Card>
                    <CardBody>
                      <div className="text-center mb-3">
                        <img
                          src={defaultAvatar}
                          alt=""
                          className="avatar-sm rounded-circle"
                        />
                        <h6 className="font-size-15 mt-3 mb-1">
                          Meeter #{meeter.mNumber}
                        </h6>
                        <p className="mb-0 text-muted">{meeter.age} Jahre</p>
                      </div>
                      <div className="d-flex mb-3 justify-content-center gap-2 text-muted">
                        <div>
                          <i className="bx bx-map align-middle text-primary"></i>{" "}
                          {meeter.states.join(", ")}
                        </div>
                        {meeter.sallary && (
                          <p className="mb-0 text-center">
                            <i className="bx bx-money align-middle text-primary"></i>{" "}
                            {meeter.sallary} €
                          </p>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
          <Modal
            isOpen={selectedMeeter}
            centered
            toggle={() => {
              setSelectedMeeter()
            }}
          >
            <div className="modal-content">
              <ModalHeader
                toggle={() => setSelectedMeeter()}
                className="modal-header"
              >
                Reminder senden
              </ModalHeader>
              <ModalBody>
                {success ? (
                  <Row>
                    <p>Reminder wurde erfolgreich gesendet</p>
                    <Col lg={12}>
                      <div className="text-end">
                        <button
                          className="btn btn-outline-secondary"
                          onClick={_ => setSelectedMeeter()}
                        >
                          Schließen
                        </button>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <p>Sind sie sicher, dass Sie den Meeter erinnern wollen?</p>
                    <Col lg={12}>
                      <div className="text-end">
                        <button
                          className="btn btn-danger me-1"
                          onClick={_ => sendMeeterReminder()}
                        >
                          Ja, Meeter erinnern
                        </button>
                        <button
                          className="btn btn-outline-secondary"
                          onClick={_ => setSelectedMeeter()}
                        >
                          Abbrechen
                        </button>
                      </div>
                    </Col>
                  </Row>
                )}
              </ModalBody>
            </div>
          </Modal>
        </TabPane>
      </TabContent>
    </React.Fragment>
  )
}

export default List
