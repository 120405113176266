export const stateOptions = [
  "Ganz Österreich",
  "Burgenland",
  "Kärnten",
  "Niederösterreich",
  "Oberösterreich",
  "Salzburg",
  "Steiermark",
  "Tirol",
  "Vorarlberg",
  "Wien",
]

export const genderOptions = ["Weiblich", "Männlich", "Diverse"]

export const homeOfficeOptions = ["Ja", "Nein"]

export const hoursOptions = [
  "Lehre",
  "Praktikum",
  "Geringfügig",
  "10h",
  "20h",
  "30h",
  "Vollzeit",
]

export const optionsPersonalityMeeter = [
  "Ich bin technisch begabt",
  "Ich bin handwerklich geschickt",
  "Die digitale Welt ist mein zu Hause",
  "Kundenkontakt ist meine Leidenschaft",
  "Organisieren macht mir Freude",
  "Ich kann sehr gut mit Zahlen",
  "Soziales Engagement zeichnet mich aus",
  "Ich übernehme gerne Verantwortung",
  "Ich kommuniziere gerne und gut",
  "Kreativität und Gestaltungsspielraum in einem Team machen mir Freude",
  "Ich bin offen für Veränderungen und gestalte gerne aktiv mit",
  "Ich achte auf Nachhaltigkeit, denn meine Zukunft ist mir wichtig",
  "Ich bin gern unterwegs",
  "Bewegung und Sport haben einen festen Platz in meinem Leben",
  "Ich verfüge über ein großes Netzwerk und freue mich über jeden neuen Kontakt",
]

export const optionsPersonalityJob = [
  "Technische Begabung",
  "Handwerkliches Geschick",
  "Digitale Fitness",
  "Freude am Kundenkontakt",
  "Organisationstalent",
  "Zahlengeschick",
  "Soziales Engagement",
  "Führungsverantwortung",
  "Kommunikationstalent",
  "Kreativität und Teamplayer",
  "Mut zur Veränderung",
  "Nachhaltigkeitsbewusstsein",
  "(Hohe) Reisebereitschaft",
  "Freude an Bewegung und Sport",
  "Kontaktfreudig",
]

export const initialMeeterValues = {
  firstname: "",
  lastname: "",
  age: "",
  phoneNr: "",
  states: [],
  workingHours: [],
  homeOffice: [],
  strengths: [],
  gender: "",
  iLikeAboutMe: "",
  peopleILikeDo: "",
  myBestFriendSays: "",
  myIdolIs: "",
  sallery: "",
  whatIWantToSay: "",
  contactWhen: "",
}

export const touchedMeeterValues = {
  firstname: false,
  lastname: false,
  iLikeAboutMe: false,
  peopleILikeDo: false,
  myBestFriendSays: false,
  myIdolIs: false,
  phoneNr: false,
}

export const initialFinderValues = {
  companyName: "",
  companySize: 1,
  contactPerson: "",
  email: "",
  field: "",
  state: "",
  vatNumber: "",
  companyNr: "",
  whyUs: "",
  street: "",
  city: "",
  postalCode: "",
}

export const touchedFinderValues = {
  companyName: false,
  contactPerson: false,
  email: false,
  field: false,
  state: false,
  vatNumber: false,
  companyNr: false,
  whyUs: false,
  street: false,
  city: false,
  postalCode: false,
}

export const companySizeOptions = [
  {
    options: [
      { label: "1-9", value: "1" },
      { label: "10-49", value: "3" },
      { label: "50-249", value: "5" },
      { label: ">249", value: "8" },
    ],
  },
]

export const stateOptionsFinder = [
  "Burgenland",
  "Kärnten",
  "Niederösterreich",
  "Oberösterreich",
  "Salzburg",
  "Steiermark",
  "Tirol",
  "Vorarlberg",
  "Wien",
]

export const finderPrices = [
  {
    monthly: 99,
    yearly: 950,
  },
  {
    monthly: 299,
    yearly: "2.880",
  },
  {
    monthly: 499,
    yearly: "4.790",
  },
  {
    monthly: 799,
    yearly: "7.690",
  },
]

const options = []
for (let index = 15; index < 80; index = index + 5) {
  options.push(index + "-" + (index + 4))
}

export const ageOptions = options

export const initialJobValues = {
  jobTitle: "",
  states: [],
  homeOffice: [],
  workingHours: [],
  startDate: new Date(),
  strengths: [],
  meeters: [],
  whyThisJob: "",
  isActive: true,
  sallery: "",
  salleryInfo: "",
}

export const touchedJobValues = {
  jobTitle: false,
  whyThisJob: false,
  sallery: false,
}
