import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap"

// import images
import meeter from "../../assets/images/meeter/meeter.jpg"
import finder from "../../assets/images/finder/finder.jpg"
import { changeLoading } from "store/actions"
import { useDispatch } from "react-redux"

const AccountDecision = props => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(changeLoading(false))
  }, [])

  const handleClick = userType => {
    localStorage.setItem("userType", userType)
    if (userType === "meeter") return (window.location.href = "/meeter-setup")
    window.location.href = "/finder-setup"
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="justify-content-md-center">
            <Col xl={4}>
              <Card>
                <CardImg top className="img-fluid" src={meeter} alt="meeter" />
                <CardBody>
                  <CardTitle className="mt-0">
                    Für Jobinteressierte:
                    <br /> Profil erstellen und gefunden werden
                  </CardTitle>
                  <CardText>
                    Erstelle dein individuelles meeter-Profil und lass dich von
                    deinem zukünftigen Arbeitgeber finden. Ganz einfach und
                    bequem.
                  </CardText>
                </CardBody>

                <CardBody>
                  <Link
                    to="#"
                    onClick={_ => handleClick("meeter")}
                    className="card-link"
                  >
                    Jetzt Meeter werden{" "}
                    <i className="mdi mdi-arrow-right ms-1" />
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col xl={4}>
              <Card>
                <CardImg top className="img-fluid" src={finder} alt="finder" />
                <CardBody>
                  <CardTitle className="mt-0">
                    Für Unternehmen:
                    <br /> Profil erstellen und Mitarbeiter:innen finden
                  </CardTitle>
                  <CardText>
                    Einfach ein Profil erstellen und Mitarbeitende finden, die
                    genau zu Ihrem Unternehmen passen.
                  </CardText>
                </CardBody>

                <CardBody>
                  <Link
                    to="#"
                    onClick={_ => handleClick("finder")}
                    className="card-link"
                  >
                    Jetzt Finder werden{" "}
                    <i className="mdi mdi-arrow-right ms-1" />
                  </Link>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AccountDecision
