import React from "react"

import { Col, FormGroup, Input, Label, Row, TabPane } from "reactstrap"

const PersonalityPane = ({ meeter, setMeeter, touched, setTouched }) => {
  function handleSalleryChange(e) {
    const re = /^[0-9\b]+$/
    if (e.target.value === "" || re.test(e.target.value)) {
      setMeeter({ ...meeter, sallery: e.target.value })
    }
  }

  return (
    <TabPane tabId={3}>
      <div>
        <Row>
          <Col lg="12">
            <FormGroup className="mb-3">
              <Label className="d-block">Ich mag an mir ...</Label>
              <textarea
                type="text"
                className="form-control"
                placeholder=""
                value={meeter.iLikeAboutMe}
                maxLength={1000}
                onChange={e =>
                  setMeeter({
                    ...meeter,
                    iLikeAboutMe: e.target.value,
                  })
                }
                onBlur={_ => setTouched({ ...touched, iLikeAboutMe: true })}
                invalid={!meeter?.iLikeAboutMe && touched.iLikeAboutMe}
              />
              <small
                className="form-text text-muted"
                style={{ fontStyle: "italic" }}
              >
                Welche Eigenschaften und Fähigkeiten zeichnen dich besonders aus
                ...
              </small>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup className="mb-3">
              <Label>
                Menschen, die ich sehr schätze, verhalten sich mir gegenüber
                wie?
              </Label>
              <textarea
                type="text"
                className="form-control"
                placeholder=""
                value={meeter.peopleILikeDo}
                maxLength={1000}
                onChange={e =>
                  setMeeter({
                    ...meeter,
                    peopleILikeDo: e.target.value,
                  })
                }
                onBlur={_ => setTouched({ ...touched, peopleILikeDo: true })}
                invalid={!meeter?.peopleILikeDo && touched.peopleILikeDo}
              />
              <small
                className="form-text text-muted"
                style={{ fontStyle: "italic" }}
              >
                Gib uns in ein paar Worten Einblick, wie andere auf dich
                reagieren
              </small>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup className="mb-3">
              <Label>
                Meine beste Freundin bzw. mein bester Freund raten mir beruflich
                folgendes zu tun ...
              </Label>
              <textarea
                type="text"
                className="form-control"
                placeholder=""
                value={meeter.myBestFriendSays}
                maxLength={1000}
                onChange={e =>
                  setMeeter({
                    ...meeter,
                    myBestFriendSays: e.target.value,
                  })
                }
                onBlur={_ => setTouched({ ...touched, myBestFriendSays: true })}
                invalid={!meeter?.myBestFriendSays && touched.myBestFriendSays}
              />
              <small
                className="form-text text-muted"
                style={{ fontStyle: "italic" }}
              >
                Beschreibe in wenigen Worten was dein beste:r Freund:in dir
                raten würde..
              </small>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup className="mb-3">
              <Label>
                Mein größtes Vorbild ist ____________, da mich folgende
                Eigenschaften besonders beeindrucken:
              </Label>
              <textarea
                type="text"
                className="form-control"
                placeholder=""
                value={meeter.myIdolIs}
                maxLength={1000}
                onChange={e =>
                  setMeeter({
                    ...meeter,
                    myIdolIs: e.target.value,
                  })
                }
                onBlur={_ => setTouched({ ...touched, myIdolIs: true })}
                invalid={!meeter?.myIdolIs && touched.myIdolIs}
              />
              <small
                className="form-text text-muted"
                style={{ fontStyle: "italic" }}
              >
                Verrate uns wer dein großes Vorbild ist und warum er/sie dein
                Vorbild ist ...
              </small>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup className="mb-3">
              <Label>Mein Gehaltswunsch (optional):</Label>
              <Input
                type="text"
                className="form-control"
                placeholder=""
                value={meeter.sallery}
                onChange={handleSalleryChange}
              />
              <small
                className="form-text text-muted"
                style={{ fontStyle: "italic" }}
              >
                Wenn du möchtest, gib hier dein monatliches Wunschgehalt (netto)
                ein oder lasse das Feld einfach frei!
              </small>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup className="mb-3">
              <Label>Was ich noch sagen möchte ist (optional):</Label>
              <textarea
                type="text"
                className="form-control"
                placeholder=""
                value={meeter.whatIWantToSay}
                maxLength={1000}
                onChange={e =>
                  setMeeter({
                    ...meeter,
                    whatIWantToSay: e.target.value,
                  })
                }
              />
              <small
                className="form-text text-muted"
                style={{ fontStyle: "italic" }}
              >
                Hier ist Platz für deine individuellen Ergänzungen (z.B.:
                Hobbys, Ausbildungen etc.)
              </small>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup className="mb-3">
              <Label>
                Wie und wann möchtest du von einem Unternehmen kontaktiert
                werden?
              </Label>
              <Input
                type="text"
                className="form-control"
                placeholder=""
                value={meeter.contactWhen}
                maxLength={500}
                onChange={e =>
                  setMeeter({
                    ...meeter,
                    contactWhen: e.target.value,
                  })
                }
              />
              <small
                className="form-text text-muted"
                style={{ fontStyle: "italic" }}
              >
                Hier kannst du die gewünschte Zeit (z.B.: Montag ab 17:00),
                deine Mobilnummer etc. angeben. <br />
                Erst wenn du deine Kontaktanfrage bestätigst, werden deine Daten
                übermittelt. Bis dahin bleibst du völlig anonym.
              </small>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <span className="text-muted">
            powered by Mag. Kordula Wagner, Psychotherapeutin, Supervisorin und
            Coach, Lehrtherapeutin bei GLE Österreich.
          </span>
        </Row>
      </div>
    </TabPane>
  )
}

export default PersonalityPane
