import {
  isEmailVerified,
  sendVerificationEmail,
} from "helpers/auth_backend_helper"
import { displayError } from "helpers/misc_helpers"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap"
import { changeLoading } from "store/actions"

// import images
import logodark from "../../assets/images/logo-dark.png"

const EmailVerification = () => {
  const dispatch = useDispatch()
  const userEmail = localStorage.getItem("email")
  const [error, setError] = useState()

  const sendConfirmationEmail = async () => {
    try {
      dispatch(changeLoading(true))
      await sendVerificationEmail()
      dispatch(changeLoading(false))
    } catch (error) {
      console.log(error)
      dispatch(changeLoading(false))
    }
  }

  const checkEmailVerified = async _ => {
    dispatch(changeLoading(true))
    const result = await isEmailVerified(localStorage.getItem("email"))

    if (!result.verified)
      displayError(
        "Bitte überprüfen Sie Ihr E-Mail Postfach und bestätigen Sie Ihre E-Mail Adresse.",
        setError
      )
    else window.location.href = "/account-auswahl"
    dispatch(changeLoading(false))
  }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mb-5 text-muted">
                <img
                  src={logodark}
                  alt=""
                  height="60"
                  className="auth-logo-dark mx-auto"
                />
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody>
                  {error ? <Alert color="danger">{error}</Alert> : null}
                  <div className="p-2">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bxs-envelope h1 mb-0 text-primary"></i>
                        </div>
                      </div>
                      <div className="p-2 mt-4">
                        <p>
                          Wir haben Ihnen eine Bestätigungs-E-Mail an folgende
                          E-Mail gesendet:{" "}
                          <span className="text-primary">{userEmail}</span>
                          <br />
                          <br />
                          Bitte klicken Sie auf den Link in der E-Mail, um Ihre
                          Adresse zu bestätigen. Danach können Sie hier
                          fortfahren.
                        </p>
                      </div>
                      <div className="mt-2">
                        <button
                          onClick={checkEmailVerified}
                          className="btn btn-dark"
                        >
                          weiter
                        </button>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Keine E-Mail erhalten? Auch nicht im Spam-Ordner?
                  <br />
                  <a
                    href="#"
                    onClick={sendConfirmationEmail}
                    className="fw-medium text-primary"
                  >
                    {" "}
                    hier die E-Mail erneut senden.
                  </a>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EmailVerification
