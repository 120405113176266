import React, { useState } from "react"
import { Card, CardBody, Col, Row, Form } from "reactstrap"
import Select from "react-select"

//Date Picker
import "react-datepicker/dist/react-datepicker.css"

const JobFilter = ({ setFilterOption, filteroption: filterOption }) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  const options = [
    {
      options: [
        { label: "offen", value: "open" },
        { label: "bestätigt", value: "accepted" },
        { label: "abgelehnt", value: "declined" },
      ],
    },
  ]

  function handleFilterChange(e) {
    setFilterOption(e.value)
  }

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card className="job-filter">
            <CardBody>
              <Form>
                <Row className="g-3 justify-content-end">
                  <Col xxl={2} lg={4}>
                    <div className="position-relative">
                      <Select
                        onChange={handleFilterChange}
                        options={options}
                        className="select2-selection"
                        placeholder="auswählen"
                      />
                    </div>
                  </Col>

                  {/* <Col xxl={2} lg={6}>
                    <div className="position-relative h-100 hstack gap-3">
                      <button
                        type="submit"
                        className="btn btn-primary h-100 w-100"
                      >
                        <i className="bx bx-search-alt align-middle"></i> Suche
                      </button>
                    </div>
                  </Col> */}
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default JobFilter
