import { post } from "./api_helper"

export async function sendContactRequest(email) {
  post("/mail/sendMail", {
    email,
    subject: "You rock: Ein Unternehmen möchte dich kennenlernen!",
    heading: "Ein Unternehmen möchte dich kennenlernen!",
    body:
      "Hallo! <br/> <br/>Glückwunsch, dein Profil findet Anklang!<br/>" +
      "Ein Unternehmen hat Interesse an deiner Person und möchte dich kennenlernen.<br/><br/>" +
      "Dein meet2find Team<br/>",
    btnText: "Jetzt ansehen",
    btnLink:
      window.location.protocol + "//" + window.location.host + "/jobangebote",
  })
}

export async function sendContactReminderRequest(email) {
  await post("/mail/sendMail", {
    email,
    subject: "Reminder: Ein Unternehmen möchte dich kennenlernen!",
    heading: "Ein Unternehmen möchte dich kennenlernen!",
    body:
      "Hallo! <br/> <br/>Glückwunsch, dein Profil findet Anklang!<br/>" +
      "Ein Unternehmen hat Interesse an deiner Person und möchte dich kennenlernen.<br/><br/>" +
      "Dein meet2find Team<br/>",
    btnText: "Jetzt ansehen",
    btnLink:
      window.location.protocol + "//" + window.location.host + "/jobangebote",
  })
}

export async function sendContactConfirmatiion(email, jid) {
  //todo link directly to user
  post("/mail/sendMail", {
    email,
    subject: "Herzlich willkommen bei meet2find",
    heading: "Jemand möchte Ihr Unternehmen kennenlernen!",
    body:
      "Glückwunsch, Ihr Unternehmen findet Anklang!<br/>" +
      "Jemand hat Interesse an der von Ihnen ausgeschriebenen Stelle und möchte Sie ebenfalls kennenlernen." +
      "Dein meet2find Team<br/>",
    btnText: "Jetzt ansehen",
    btnLink:
      window.location.protocol +
      "//" +
      window.location.host +
      `/finder-jobs/${jid}/meeters`,
  })
}

export async function sendAboConfirmationMeeter(email) {
  post("/mail/sendMail", {
    email,
    subject: "Herzlichen Glückwunsch!",
    heading: "Herzlichen Glückwunsch!",
    body:
      "Du hast soeben die Registrierung bei meet2find.at abgeschlossen. Alle Details zu deinem deinem Profil findest du im Log-in-Bereich auf meet2find.at.<br/>Wenn du Fragen hast, kontaktiere uns einfach unter info@meet2find.at." +
      "<br/> <br/>Dein meet2find Team<br/>",
    btnText: "Jetzt anmelden",
    btnLink: window.location.protocol + "//" + window.location.host + `/login`,
  })
}

export async function sendAboConfirmationFinder(email) {
  post("/mail/sendMail", {
    email,
    subject: "Herzlichen Glückwunsch!",
    heading: "Herzlichen Glückwunsch!",
    body:
      "Sie haben soeben ein Abo auf meet2find.at abgeschlossen. Alle Details zu Ihrem Abo finden Sie in Ihrem Log-in-Bereich auf meet2find.at.<br/>Wenn Sie Fragen haben, kontaktieren Sie uns einfach unter info@meet2find.at." +
      "<br/> <br/>Ihr meet2find Team<br/>",
    btnText: "Jetzt anmelden",
    btnLink: window.location.protocol + "//" + window.location.host + `/login`,
  })
}
