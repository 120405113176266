import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import ReactApexChart from "react-apexcharts"

import getChartColorsArray from "../../components/Common/ChartsDynamicColor"
import { getStatistics } from "helpers/misc_backend_helper"
import _, { last } from "lodash"
import { useDispatch } from "react-redux"
import { changeLoading } from "store/actions"

const Statistic = ({ dataColors, statisticType, label }) => {
  const apexearningChartColors = getChartColorsArray(dataColors)
  const [monthOptions, setMonthOptions] = useState([])
  const [statistics, setStatistics] = useState([])
  const [selectedMonth, setSelectedMonth] = useState()
  const [data, setData] = useState([])
  const dispatch = useDispatch()
  const [growth, setGrowth] = useState(0)

  const options = {
    chart: {
      toolbar: "false",
      dropShadow: {
        enabled: !0,
        color: "#000",
        top: 18,
        left: 7,
        blur: 8,
        opacity: 0.2,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    colors: apexearningChartColors,
    stroke: {
      curve: "smooth",
      width: 3,
    },
  }

  const series = [
    {
      name: "",
      data: [...data],
    },
  ]

  const onChangeMonth = value => {
    setSelectedMonth(value)
    handleSelectionChange(value)
  }

  const getMonthOptions = () => {
    var monthNames = [
      "Jänner",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember",
    ]

    var today = new Date()
    var d
    var months = []

    for (var i = 6; i >= 0; i -= 1) {
      d = new Date(today.getFullYear(), today.getMonth() - i, 1)
      months.push({ text: monthNames[d.getMonth()], key: d.getMonth() })
    }

    setMonthOptions(months)
    setSelectedMonth(months[6].key)
  }

  useEffect(() => {
    getMonthOptions()
    loadStatistics()
  }, [])

  const loadStatistics = async () => {
    dispatch(changeLoading(true))
    const stats = await getStatistics()
    const result = _.groupBy(stats[statisticType], ({ dateCreated }) =>
      new Date(dateCreated).getMonth()
    )
    setStatistics(result)
    dispatch(changeLoading(false))
  }

  const handleSelectionChange = month => {
    const monthValues = statistics[month]

    var dayStatistic = []
    if (monthValues)
      for (let i = 0; i < daysInMonth(month + 1); i++)
        dayStatistic.push(
          monthValues.filter(m => new Date(m.dateCreated).getDate() == i + 1)
            .length
        )

    setData(dayStatistic)
    calculateGrowth()
  }

  const calculateGrowth = () => {
    var current = statistics[new Date().getMonth()]?.length ?? 0
    var lastMonth = statistics[new Date().getMonth() - 1]?.length ?? 0

    if (lastMonth == 0) return setGrowth(0)
    var growth = ((current - lastMonth) / lastMonth) * 100

    setGrowth(growth)
  }

  useEffect(() => handleSelectionChange(new Date().getMonth()), [statistics])

  const daysInMonth = month =>
    new Date(new Date().getFullYear(), month, 0).getDate()

  return (
    <React.Fragment>
      <Col>
        <Card>
          <CardBody>
            <div className="clearfix">
              <div className="float-end">
                <div className="input-group input-group-sm">
                  <select
                    className="form-select form-select-sm"
                    value={selectedMonth}
                    onChange={e => {
                      onChangeMonth(e.target.value)
                    }}
                  >
                    {monthOptions?.map((month, idx) => (
                      <option key={idx} value={month.key}>
                        {month.text}
                      </option>
                    ))}
                  </select>
                  <label className="input-group-text">Month</label>
                </div>
              </div>
              <h4 className="card-title mb-4">{label}</h4>
            </div>

            <Row>
              <Col lg="3">
                <div className="text-muted">
                  <div className="mb-4">
                    <p>Dieser Monat:</p>
                    <h4>
                      {statistics[new Date().getMonth()]?.length ?? 0} neue{" "}
                      {label}
                    </h4>
                    <div>
                      <span
                        className={
                          "badge font-size-12 me-1 badge-soft-" +
                          (growth > 0
                            ? "success"
                            : growth < 0
                            ? "danger"
                            : "warning")
                        }
                      >
                        {growth > 0 ? "+" : ""}
                        {Math.round(growth)} %{" "}
                      </span>{" "}
                      gegenüber letzten Monat
                    </div>
                  </div>
                </div>
              </Col>

              <Col lg="9">
                <div id="line-chart" dir="ltr">
                  <ReactApexChart
                    series={series}
                    options={options}
                    type="line"
                    height={320}
                    className="apex-charts"
                  />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default Statistic
