import { addMeeterRequest } from "helpers/finder_backend_helper"
import { sendContactRequest } from "helpers/mail_helper"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import {
  Alert,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import { changeLoading } from "store/actions"

const Sidebar = ({ meeter, history }) => {
  const [modal, setModal] = useState(false)
  const [success, setSuccess] = useState(false)
  const [type, setType] = useState("")
  const [error, setError] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const dispatch = useDispatch()
  const { jid } = useParams()

  const handleSubmit = async status => {
    try {
      dispatch(changeLoading(true))
      await addMeeterRequest(jid, meeter.id, status)
      if (status === "open") sendContactRequest(meeter.email)
      setModal(false)
      setType(status)
      setDeleteModal(false)
      setSuccess(true)
      dispatch(changeLoading(false))
    } catch (error) {
      console.log(error)
      setError(error)
      setTimeout(() => {
        setError()
      }, 3000)
    }
    dispatch(changeLoading(false))
  }

  return (
    <React.Fragment>
      <Col lg={3}>
        <Card>
          <CardBody>
            <ul className="list-unstyled vstack gap-3 mb-0">
              {meeter?.confirmed && (
                <>
                  <li>
                    <div className="d-flex">
                      <i className=" bx bxs-phone font-size-18 text-secondary"></i>

                      <div className="ms-3">
                        <h6 className="mb-1 fw-semibold">Telefonnummer:</h6>
                        <span className="text-muted">{meeter.phoneNr}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex">
                      <i className="bx bx-envelope font-size-18 text-secondary"></i>

                      <div className="ms-3">
                        <h6 className="mb-1 fw-semibold">E-Mail:</h6>
                        <span className="text-muted">{meeter.email}</span>
                      </div>
                    </div>
                  </li>
                  {meeter.contactWhen && (
                    <li>
                      <div className="d-flex">
                        <i className=" bx bxs-time font-size-18 text-secondary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">
                            Wann kann man mich erreichen?
                          </h6>
                          <span className="text-danger">
                            {meeter.contactWhen}
                          </span>
                        </div>
                      </div>
                    </li>
                  )}
                </>
              )}
              <li>
                <div className="d-flex">
                  <i className="bx bxs-map-pin font-size-18 text-primary"></i>
                  <div className="ms-3">
                    <h6 className="mb-1 fw-semibold">
                      Gewünschter Arbeitsort:
                    </h6>
                    <span className="text-muted">
                      {meeter.states?.join(", ")}
                    </span>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex">
                  <i className="bx bx-calendar font-size-18 text-primary"></i>
                  <div className="ms-3">
                    <h6 className="mb-1 fw-semibold">Alter:</h6>
                    <span className="text-muted">{meeter.age} Jahre</span>
                  </div>
                </div>
              </li>
              {meeter.sallery && (
                <li>
                  <div className="d-flex">
                    <i className="bx bx-money font-size-18 text-primary"></i>
                    <div className="ms-3">
                      <h6 className="mb-1 fw-semibold">Gewünschtes Gehalt:</h6>
                      <span className="text-muted">{meeter.sallery} €</span>
                    </div>
                  </div>
                </li>
              )}

              {meeter.gender && (
                <li>
                  <div className="d-flex">
                    <i className="bx bx-user font-size-18 text-primary"></i>
                    <div className="ms-3">
                      <h6 className="mb-1 fw-semibold">Geschlecht:</h6>
                      {meeter.gender}
                    </div>
                  </div>
                </li>
              )}
              {meeter.workingHours && (
                <li>
                  <div className="d-flex">
                    <i className="bx bx-time-five font-size-18 text-primary"></i>
                    <div className="ms-3">
                      <h6 className="mb-1 fw-semibold">
                        Verfügbare Wochenstunden:
                      </h6>
                      <span className="text-muted">
                        {meeter.workingHours.join(", ")}
                      </span>
                    </div>
                  </div>
                </li>
              )}
              <li>
                <div className="d-flex">
                  <i className="bx bx-home-circle font-size-18 text-primary"></i>
                  <div className="ms-3">
                    <h6 className="mb-1 fw-semibold">Home-Office:</h6>
                    <span className="text-muted">{meeter.homeOffice}</span>
                  </div>
                </div>
              </li>

              {!meeter?.confirmed && (
                <>
                  <li className="hstack gap-2 mt-3">
                    <button
                      className="btn btn-soft-success w-100"
                      onClick={() => setModal(true)}
                    >
                      Kontaktanfrage senden
                    </button>
                  </li>
                  <li>
                    <button
                      className="btn btn-soft-danger w-100"
                      onClick={() => setDeleteModal(true)}
                    >
                      Ablehnen
                    </button>
                  </li>
                </>
              )}
            </ul>
          </CardBody>
        </Card>
      </Col>
      {/* Modal */}
      <Modal
        isOpen={modal}
        toggle={() => {
          setModal()
        }}
        id="applyJobs"
        centered
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => setModal()}
            id="applyJobsLabel"
            className="modal-header"
          >
            Freigabe
          </ModalHeader>
          <ModalBody>
            <Row>
              {error ? <Alert color="danger">{error}</Alert> : null}
              <h4>Kontaktanfrage senden!</h4>
              <p>
                Dieses Profil entspricht Ihren Anforderungen! Lassen Sie es den
                meeter wissen.
              </p>
              <Col lg={12}>
                <div className="text-end">
                  <button
                    className="btn btn-outline-dark"
                    onClick={_ => setModal(false)}
                  >
                    Abbrechen
                  </button>
                  <button
                    className="btn btn-success ms-2"
                    onClick={_ => handleSubmit("open")}
                  >
                    Ja, Kontaktanfrage senden
                  </button>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>
      <Modal
        isOpen={success}
        toggle={() => {
          setSuccess()
        }}
        centered
      >
        <div className="modal-content">
          <ModalHeader className="modal-header">Freigabe</ModalHeader>
          <ModalBody>
            <Row>
              <h4>
                {type === "open"
                  ? "Kontaktanfrage gesendet"
                  : "Meeter abgelehnt"}
              </h4>
              <p>
                {type === "open"
                  ? "Ihre Kontaktanfrage wurde soeben an den meeter versendet. Nach dessen Freigabe erhalten Sie eine Nachricht."
                  : "Schade das dieser Meeter nichts für Sie ist. Sie können aber gerne weitere Meeter ansehen"}
              </p>
              <Col lg={12}>
                <div className="text-end">
                  <button
                    className="btn btn-secondary me-1"
                    onClick={() =>
                      history.push("/finder-jobs/" + jid + "/meeters")
                    }
                  >
                    Zurück zum Dashboard
                  </button>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>
      <Modal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal()
        }}
        centered
        id="applyJobs"
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => setDeleteModal()}
            id="applyJobsLabel"
            className="modal-header"
          >
            Finder ablehnen
          </ModalHeader>
          <ModalBody>
            <Row>
              <h4>Meeter ablehnen!</h4>
              <p>
                Sind Sie sicher dass dieser Meeter nichts für Ihr Unternehmen
                ist?
              </p>
              <Col lg={12}>
                <div className="text-end">
                  <button
                    className="btn btn-danger me-1"
                    onClick={_ => handleSubmit("deleted")}
                  >
                    <i className=" bx bx-block align-middle me-1"></i>
                    Ja, Meeter ablehnen
                  </button>
                  <button
                    className="btn btn-outline-secondary"
                    onClick={_ => setDeleteModal(false)}
                  >
                    Abbrechen
                  </button>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default Sidebar
