import React from "react"
import PropTypes from "prop-types"

import { connect } from "react-redux"

import { Link } from "react-router-dom"

// Redux Store
import { toggleLeftmenu } from "../../store/actions"

import logo from "../../assets/images/logo-light.png"
import logoSmall from "../../assets/images/logo-small.png"

const Header = props => {
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link
                to="#"
                onClick={_ => (window.location.href = "https://meet2find.at")}
                className="logo logo-light"
              >
                <span className="logo-sm">
                  <img src={logoSmall} alt="" height="40" />
                </span>
                <span className="logo-lg">
                  <img src={logo} alt="" height="40" />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  leftMenu: PropTypes.any,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, leftMenu } = state.Layout
  return { layoutType, leftMenu }
}

export default connect(mapStatetoProps, {
  toggleLeftmenu,
})(Header)
