import React from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"

//Import Images
import error from "../../assets/images/error-img.png"

const Pages500 = () => {
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <h1 className="display-2 fw-medium">
                  5<i className="bx bx-buoy bx-spin text-primary display-3" />0
                </h1>
                <h4 className="text-uppercase">
                  Leider ist etwas schiefgelaufen....
                </h4>
                <p>
                  Unsere Entwickler bemühen sich, das Problem so schnell wie
                  möglich zu beheben.
                </p>
                <div className="mt-4 text-center">
                  <Link
                    className="btn btn-primary waves-effect waves-light "
                    to="#"
                    onClick={_ =>
                      (window.location.href = "https://meet2find.at")
                    }
                  >
                    Zurück
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="8" xl="6">
              <div>
                <img src={error} alt="" className="img-fluid" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Pages500
