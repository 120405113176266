import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import Settings from "../pages/Authentication/Settings"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import AccountDecision from "pages/AccountDecision/AccountDecision"
import MeeterSetup from "pages/Setup-Meeter/MeeterSetup"
import MeeterProfile from "pages/MeeterProfile/MeeterProfile"
import FinderSetup from "pages/Setup-Finder/FinderSetup"
import DashboardMeeter from "pages/Dashboard-Meeter"
import MeeterJobOffers from "pages/MeeterJobOffers"
import MeeterJobOfferDetail from "pages/MeeterJobOfferDetail"
import DashboardFinder from "../pages/Dashboard-Finder"
import FinderJobOffers from "pages/FinderJobs"
import FinderJobCreation from "pages/FinderJobCreation/index"
import MeeterList from "pages/FinderCandidateList"
import MyProfile from "pages/MeeterMyProfile/MyProfile"
import FinderJobOfferDetail from "pages/FinderJobOfferDetail"
import ConfirmMail from "pages/Authentication/page-confirm-mail"
import EmailVerification from "pages/Authentication/auth-email-verification"
import Pages500 from "pages/Utility/pages-500"
import MeeterEdit from "pages/Setup-Meeter/MeeterEdit"
import FinderEdit from "pages/Setup-Finder/FinderEdit"
import ResetPassword from "pages/Authentication/ResetPassword"
import Statistics from "pages/Statistics"

const authProtectedRoutes = [
  //finder
  { path: "/finder-dashboard", component: DashboardFinder },
  { path: "/finder-jobs", component: FinderJobOffers },
  { path: "/finder-jobs/:id", component: FinderJobOfferDetail },
  { path: "/finder-jobs/:id/meeters", component: MeeterList },
  { path: "/finder-jobs/:jid/meeters/:mid", component: MeeterProfile },
  { path: "/neuer-job", component: FinderJobCreation },
  { path: "/job-bearbeiten/:id", component: FinderJobCreation },
  { path: "/finder-bearbeiten", component: FinderEdit },

  //meeter
  { path: "/dashboard", component: DashboardMeeter },
  { path: "/jobangebote", component: MeeterJobOffers },
  { path: "/jobangebote/:id", component: MeeterJobOfferDetail },
  { path: "/mein-profil", component: MyProfile },
  { path: "/meeter-bearbeiten", component: MeeterEdit },

  //both
  { path: "/einstellungen", component: Settings },
]

const authProtectedRoutesWithoutLayout = [
  { path: "/finder-setup", component: FinderSetup },
  { path: "/meeter-setup", component: MeeterSetup },
  { path: "/account-auswahl", component: AccountDecision },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/email-verification", component: EmailVerification },
  { path: "/email-confirmation/:email", component: ConfirmMail },
  { path: "/passwort-vergessen", component: ForgetPwd },
  { path: "/passwort-reset", component: ResetPassword },
  { path: "/register", component: Register },
  { path: "/500", component: Pages500 },
  { path: "/statistics", component: Statistics },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/login" />,
  },
]

export { authProtectedRoutes, authProtectedRoutesWithoutLayout, publicRoutes }
