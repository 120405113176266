import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import JobFilter from "./JobFilter"
import JobData from "./JobData"
import { useDispatch } from "react-redux"
import { changeLoading } from "store/actions"
import { getFinderJobs } from "helpers/finder_backend_helper"

const MeeterJobOffers = props => {
  const [finder, setFinder] = useState()
  const [filter, setFilter] = useState("")
  const dispatch = useDispatch()

  useEffect(() => {
    async function getCurrentFinder() {
      dispatch(changeLoading(true))
      const result = await getFinderJobs()
      setFinder(result)
      dispatch(changeLoading(false))
    }
    getCurrentFinder()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Jobs" breadcrumbItem="Erstellte Jobangebote" />
          <JobFilter
            finder={finder}
            setFilter={setFilter}
            filter={filter}
            history={props.history}
          />
          <JobData finder={finder} filter={filter} />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MeeterJobOffers
