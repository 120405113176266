import React, { useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
} from "reactstrap"

import classnames from "classnames"
import { Link } from "react-router-dom"
import {
  initialMeeterValues,
  touchedMeeterValues,
} from "../../constants/SetupOptions"
import { useDispatch } from "react-redux"
import { changeLoading } from "store/actions"
import { createMeeter } from "helpers/meeter_backend_helper"
import GeneralInfoPane from "./Panes/GeneralInfoPane"
import StrengthsPane from "./Panes/StrengthsPane"
import PersonalityPane from "./Panes/PersonalityPane"
import ConfirmationPane from "./Panes/ConfirmationPane"
import { sendAboConfirmationMeeter } from "helpers/mail_helper"

const MeeterSetup = props => {
  const [meeter, setMeeter] = useState(initialMeeterValues)
  const [touched, setTouched] = useState(touchedMeeterValues)
  const [error, setError] = useState(false)
  const [activeTabVartical, setoggleTabVertical] = useState(1)
  const dispatch = useDispatch()
  const navItems = [
    "Generelle Infos",
    "Das liegt mir",
    "Das zeichnet mich aus",
    "Zusammenfinden",
  ]

  function toggleTab() {
    if (allFieldsAreFilledOut())
      if (activeTabVartical === 3) handleSubmit()
      else toggleTabVertical(activeTabVartical + 1)
    else {
      setError(true)
      setTimeout(() => {
        setError(false)
      }, 3000)
    }
  }

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      if (tab >= 1 && tab <= 6) setoggleTabVertical(tab)
      setTimeout(() => {
        scrollTo(0, 0)
      }, 50)
    }
  }

  function allFieldsAreFilledOut() {
    if (activeTabVartical === 1) {
      setTouched({
        firstname: true,
        lastname: true,
        phoneNr: true,
      })

      if (meeter.states.length == 0) return false
      if (meeter.workingHours.length == 0) return false
      if (!meeter.homeOffice) return false
      if (!meeter.gender) return false
      if (!meeter.phoneNr) return false
      if (!meeter.firstname) return false
      if (!meeter.lastname) return false
      if (!meeter.age) return false
    }
    if (activeTabVartical === 2) {
      if (!meeter.strengths || meeter.strengths.length < 3) return false
    }

    if (activeTabVartical === 3) {
      setTouched({
        iLikeAboutMe: true,
        peopleILikeDo: true,
        myBestFriendSays: true,
        myIdolIs: true,
      })

      if (!meeter.iLikeAboutMe) return false
      if (!meeter.peopleILikeDo) return false
      if (!meeter.myBestFriendSays) return false
      if (!meeter.myIdolIs) return false
    }

    return true
  }

  async function handleSubmit() {
    dispatch(changeLoading(true))
    const userEmail = localStorage.getItem("email")
    meeter.email = userEmail
    await createMeeter({ ...meeter, createdAt: new Date(), isActive: true })
    await sendAboConfirmationMeeter(meeter.email)
    toggleTabVertical(activeTabVartical + 1)
    dispatch(changeLoading(false))
  }

  return (
    <React.Fragment>
      <div
        className="page-content bg-primary bg-soft"
        style={{ minHeight: "850px" }}
      >
        <Container fluid={true}>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Profil erstellen</h4>
                  <div className="vertical-wizard wizard clearfix vertical">
                    <div className="steps clearfix">
                      <ul>
                        {navItems.map((item, idx) => (
                          <NavItem
                            key={idx + 1}
                            className={classnames({
                              current: activeTabVartical === idx + 1,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTabVartical === idx + 1,
                              })}
                              disabled
                            >
                              <span className="number">{idx + 1}.</span> {item}
                            </NavLink>
                          </NavItem>
                        ))}
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent
                        activeTab={activeTabVartical}
                        className="body"
                      >
                        <GeneralInfoPane
                          meeter={meeter}
                          setMeeter={setMeeter}
                          touched={touched}
                          setTouched={setTouched}
                        />

                        <StrengthsPane meeter={meeter} setMeeter={setMeeter} />

                        <PersonalityPane
                          meeter={meeter}
                          setMeeter={setMeeter}
                          touched={touched}
                          setTouched={setTouched}
                        />

                        <ConfirmationPane />
                      </TabContent>
                    </div>

                    <div className="actions clearfix">
                      <ul>
                        {activeTabVartical < 4 && (
                          <>
                            {error && (
                              <li>
                                <p className="text-danger">
                                  Es müssen alle Pflichtfelder befüllt sein
                                </p>
                              </li>
                            )}
                            <li
                              className={
                                activeTabVartical === 1
                                  ? "previous disabled"
                                  : "previous"
                              }
                            >
                              <Link
                                to="#"
                                className="btn"
                                onClick={() => {
                                  toggleTabVertical(activeTabVartical - 1)
                                }}
                              >
                                Zurück
                              </Link>
                            </li>
                            <li>
                              <button
                                className="next btn btn-primary"
                                onClick={toggleTab}
                              >
                                {activeTabVartical === 3
                                  ? "Speichern"
                                  : "Weiter"}
                              </button>
                            </li>
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MeeterSetup
