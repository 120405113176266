import React from "react"
import { Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import Components
import Statistic from "./statistic"

const Statistics = props => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Dashboards" breadcrumbItem="Statistik" />
          <Row>
            <Statistic
              dataColors='["--bs-primary"]'
              statisticType="findersCreated"
              label="Finders"
            />
          </Row>
          <Row>
            <Statistic
              dataColors='["--bs-primary"]'
              statisticType="meetersCreated"
              label="Meeters"
            />
          </Row>
          <Row>
            <Statistic
              dataColors='["--bs-primary"]'
              statisticType="jobsCreated"
              label="Jobs"
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Statistics
